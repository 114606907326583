@import 'scss/responsive.scss';
@import 'scss/variables.scss';

.container {
  overflow: hidden;
  height: 100%;
}
.breadcrumbsOuterContainer {
  display: inline-block; // to make the div wrap tightly around the content
}
.breadcrumbsContainer {
  display: flex;
  flex-direction: column;
}
.nameInput {
  margin-bottom: 20px;
}

.tabContainer {
  padding-top: 32px;
  width: initial;
  height: 80%;
}

.toFacilitators {
  font-size: 1.6rem;
  color: var(--deeper-mid-tone);
}

.form {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  background-color: var(--background);

  @media #{$mobile} {
    border: none;
  }
}

.section {
  margin: 0;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  &:not(:last-child) {
    margin-right: 1px;
    padding-left: 0px;
  }

  &:not(:first-child) {
    margin-left: 1px;
  }
}

.saveButton {
  padding: 0 3rem !important;
}

@media #{$phone} {
  .form {
    flex-wrap: wrap;
    .section:first-child {
      border: none;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .section:last-child {
      border: none;
      margin-left: 0px;
    }
  }
}

.imageUploader {
  height: 25rem;
}
