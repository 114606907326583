.wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.spinner {
  animation: spin 0.75s linear infinite;
  border-radius: 50%;
  border: 3px solid var(--brand);
  border-top-color: var(--brand-contrast);
  display: inline-block;
  height: 50px;
  width: 50px;
}

.small {
  height: 25px;
  width: 25px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
