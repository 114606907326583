@import '../../../scss/responsive.scss';
@import '../../../scss/variables.scss';

$transition-time: 0.25s;

.headerContainer {
  .borderComponent {
    position: absolute;
    left: 5rem;
    right: 5rem;
    &.isSmall {
      right: 0;
      left: 0;
    }
    bottom: 0;
    height: 1px;
    border-bottom: 1px solid var(--semi-mid-tone);
  }
  padding: 0 5rem;

  width: 100vw;
  position: fixed;
  top: 0;
  height: $header-height;

  // orignal state
  color: var(--primary);
  background-color: var(--background-mid-tone);

  z-index: 1000;
  display: flex;
  justify-content: space-between;

  :global(.isRtl) & {
    right: unset;
    left: 1.5rem;
  }
  transition: $transition-time all;

  * > * {
    transition: $transition-time all;
  }

  &.smallContainer {
    padding-top: 0px;
  }
}

.whiteText {
  color: var(--background);
  > * {
    color: var(--background);
  }
}
.blackText {
  color: var(--primary);
  > * {
    color: var(--primary);
  }
}

.darkMode {
  color: var(--brand-contrast);
}

.whiteBackground {
  background-color: var(--background-mid-tone);
}
.blackBackground {
  background-color: var(--primary);
}

.headerContainerBox {
  background-color: black;
}

.scrolledHeaderContainer {
  position: fixed;
  top: 0;
  height: $header-height-collapsed;
  padding-top: 0px;
  transition: $transition-time all;
  * > * {
    transition: $transition-time all;
  }

  .logoContainer,
  .logo {
    height: 50px;
    width: 160px;
  }

  .rightWrapper {
    padding-bottom: 16px;
    height: $header-height-collapsed;
  }
}

.logoContainer,
.logo {
  height: 80px;
  width: 280px;
  // so that the logo will not cover the burger menu icon
  max-width: calc(100vw - 52px * 2);
  transition: $transition-time all;
  :global(.isRtl) & {
    margin: 0 3rem 0 0;
  }
  cursor: pointer;
}

.inverted {
  filter: invert(100);
}

#wrapper {
  display: flex;
  align-items: center;

  &:not(.whiteText) {
    color: var(--primary);
  }
}
.leftWrapper {
  @extend #wrapper;
  overflow-x: visible;
  &.isSmall {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.isMedium {
    width: 100%;
  }
}

.rightWrapper {
  @extend #wrapper;
  transition: $transition-time all;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
  margin-top: 4px;
  padding-bottom: 16px;
  height: $header-height;
}

.returnButton {
  font-family: Roboto, sans-serif;
  padding-left: 8px;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1.7rem;
  cursor: pointer;
}

.burgerLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.small {
    position: absolute;
    left: 3rem;
  }
  margin-right: 8px;
  [data-theme='dark'] & {
    filter: invert(1);
  }
}

.logoContainer {
  [data-theme='dark'] & {
    filter: invert(1);
  }
}

@media #{$phone} {
  .loggedInHeaderContainer {
    align-items: center;
    right: unset;
    left: unset;
    :global(.isRtl) & {
      padding-left: 3rem;
      padding-right: unset;
    }
  }

  .loggedOutLinkContainer {
    padding: 1rem;
    justify-content: center;
    width: 6.9rem;
  }
}

:export {
  smallBreakpoint: $breakpoint-mobile;
  largeBreakpoint: $breakpoint-xl;
}
