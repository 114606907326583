@import "../../scss/mixins";
@import "../../scss/responsive";
@import "../../scss/typography";
@import "../../scss/variables";

$link-top-spacing: 28px;

.back {
  position: absolute;
  top: $link-top-spacing;
  left: 50%;
  transform: translateX(-50%);

  font-size: 2.2rem;
  top: 2rem;
  @media #{$mobile} {
    height: 2rem; /* place it at the same height as the slick dots  */
    top: $link-top-spacing;
  }


  display: flex;
  align-items: center;

  cursor: pointer;
}

.backArrow {
  @include invert;

  width: 30px;
  margin-right: 15px;
}

.backLabel {
  color: $font-color-base;
  font-size: 14px;
  margin-top: 2px;
}

.prev,
.next {
  position: absolute;
  top: $link-top-spacing;

  display: flex;
  align-items: center;

  cursor: pointer;
}

$prev-next-spacing: $link-top-spacing;
$prev-next-margin: 15px;

.prev {
  left: $prev-next-spacing;
  [dir="rtl"] & {
    left: initial;
    right: $prev-next-spacing;
  }
}

.next {
  right: $prev-next-spacing;
  [dir="rtl"] & {
    right: initial;
    left: $prev-next-spacing;
  }
}

.prevArrow,
.nextArrow  {
  width: 30px;
}

.prevArrow {
  margin-right: $prev-next-margin;
  [dir="rtl"] & {
    margin-right: initial;
    margin-left: $prev-next-margin;
    transform: scaleX(-1);
  }

  @media #{$mobile} {
    width: 2.5rem;
    margin-right: 1rem;
    [dir="rtl"] & {
      margin-right: initial;
      margin-left: 1rem;
    }
  }
}

.nextArrow {
  transform: scaleX(-1);
  width: 30px;
  margin-left: $prev-next-margin;
  [dir="rtl"] & {
    margin-left: initial;
    margin-right: $prev-next-margin;
    transform: scaleX(1);
  }

  @media #{$mobile} {
    width: 2.5rem;
    margin-left: 1rem;
    [dir="rtl"] & {
      margin-left: initial;
      margin-right: 1rem;
    }
  }
 
}

.prevLabel,
.nextLabel  {
  font-size: 14px;
  margin-top: 2px;

  @media #{$mobile} {
    font-size: 1.9rem;
  }
}

.areaRight {
  display: block;
  height: 100%;
  overflow: auto;
  color: $font-color-inverse;

  @media #{$mobile} {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 3rem;
    overflow: unset;
  }
}

.areaLeft {
  @media #{$mobile} {
    width: 100%;
    display: block;
    padding: 0 5rem;
  }
}

.areaRightContainer {
  position: relative;

  @-moz-document url-prefix() {
    padding-bottom: 6vh;
  }

  @media #{$mobile} {
    top: 70px;  
    position: unset;
  }
}

.header {
  display: flex;
}

.editLinksArea {
  margin-bottom: 2.5rem;

  @media #{$mobile} {
    margin-bottom: 1rem;  
  }
}

.editLink {
  @extend .label-edit;
  
  display: block;
  width: fit-content;
  
  @media #{$mobile} {
    font-size: 1.9rem;
    margin-top: 1rem;
  }
}

.title {
  font-size: 3.33rem;
  line-height: 1.09;
  margin-bottom: 25px;

  @media #{$mobile} {
    margin-top: 6rem;
  }
}

.content {
  font-size: 1.56rem;
  line-height: 1.73;
  margin-top: 3.0rem;

  @media #{$mobile} {
    width: 70vw;
    min-height: 32vh;
    margin-bottom: 2.5rem;
  }
}

.paragraphHeadline {
  font-size: 1.6rem;
  font-weight: $font-weight-medium;
  margin-bottom: 0.6rem;

  @media #{$mobile} {
    font-size: 2.4rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

}

.paragraph {
  &:not(:last-child) {
    margin-bottom: 2.5rem;

    @media #{$mobile} {
      font-size: 2.2rem;
      margin-bottom: 5rem;
    }
  }
}

ul.challengeListTwoColumns {
  @media #{$desktop-xxl} {
    grid-template-columns: repeat(2, 1fr);
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.applicationMode {
  margin-top: 2rem;
}
