@import '../../scss/mixins.scss';
@import '../../scss/responsive.scss';
@import '../../scss/variables.scss';

.slider {
  background: var(--semi-mid-tone);
  .slide {
    display: flex;
    justify-content: center;
  }
}

.slideContainer {
  width: 100%;

  @media #{$mobile} {
    padding: 1.5rem 3rem !important;
  }
}

.container {
  flex-wrap: nowrap;
  height: 100vh;
  font-size: 1.9rem;
  line-height: 2.6rem;
  @media #{$desktop-xxl} {
    padding: 14vh 0vh;
  }
  @media #{$desktop-xl} {
    max-width: 1200px;
    margin: auto;
  }
  &.containerBlue {
    @media #{$mobile} {
      font-size: 2.4rem;
      padding: 1.5rem 0rem;
    }
  }

  @media #{$mobile} {
    height: 100%;
    padding: 1.5rem 0rem;
    font-size: 2.4rem;
  }
  [dir='rtl'] & {
    direction: rtl;
  }
}

.areaContainer,
.contentContainer {
  overflow: auto;

  @media #{$mobile} {
    padding-top: 0.5rem;
  }
}

.paragraph {
  margin-top: 2rem;
  text-align: left;
  width: 100%;

  @media #{$mobile} {
    font-size: 3rem;
    line-height: 4rem;
  }
  [dir='rtl'] & {
    text-align: right;
  }
}

.areaContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media #{$mobile} {
    flex-direction: column;
  }

  @mixin area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;
  }
  .areaLeft {
    @include area;
    width: 50%;

    @media #{$mobile} {
      width: 100%;
      height: unset;
    }
  }
  .areaRight {
    @include area;
    width: 50%;
    padding: 5rem 10rem;

    @media #{$mobile} {
      width: 100%;
      padding: 0rem 3rem;
      padding-bottom: 3rem;
    }
    @media #{$desktop} {
      padding: 5rem 5rem 5rem 10rem;
    }
    @media #{$desktop-xxl} {
      padding-right: 0;
    }

    [dir='rtl'] & {
      @media #{$desktop} {
        padding: 5rem 10rem 5rem 5rem;
      }
    }
  }
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 2.6em 0;

  .item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0 3.5rem 0;

    .itemImg {
      margin-right: 5rem;

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: 5rem;
      }
      img {
        width: 10rem;

        @media #{$mobile} {
          height: 7rem;
        }
      }
    }

    .itemText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      max-width: 120rem;

      .label {
        text-align: left;
        width: 100%;
        font-weight: 500;
        [dir='rtl'] & {
          text-align: right;
        }
      }
    }
  }
}

%imgContainer {
  background-image: url('#{$image-path}/backgrounds/main.jpg');

  background-position: center top;
  background-size: cover;
  @media #{$mobile} {
    width: 100%;
    max-height: 20rem;
    background-size: cover;
    background-position: center 20%;
  }
}

.imgContainer1 {
  width: 100%;
  height: 500px;
  @extend %imgContainer;
}

.slideHeader {
  font-size: 3.5rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  line-height: 4.5rem;
  width: 100%;

  @media #{$mobile} {
    margin-top: 2rem;
  }
  [dir='rtl'] & {
    text-align: right;
  }
}

.slideSubHeader {
  font-size: 3.5rem;
  text-align: left;
  line-height: 4.5rem;
  width: 100%;

  @media #{$mobile} {
    margin-top: 2rem;
  }
  [dir='rtl'] & {
    text-align: right;
  }
}

.contentList {
  @include reset-list-style;
  counter-reset: content-list;

  display: flex;
  margin-top: 10vh;
}

.contentItem {
  position: relative;

  font-size: 1.92rem;
  line-height: 3.07rem;
  text-align: left;

  &:not(:last-child) {
    margin-right: 100px;
  }

  &:before {
    content: counter(content-list);
    counter-increment: content-list;

    position: absolute;
    bottom: 8px;
    left: -60px;

    display: flex;
    align-items: baseline;

    font-size: 19.2rem;
    opacity: 0.18;
  }
}
.wrapper {
  overflow: hidden;
  max-height: 100%;
  @media #{$desktop} {
    padding-right: 5rem;
  }
  @media #{$desktop-xxl} {
    padding-right: 0rem;
  }
  @media #{$mobile} {
    padding: 0 3rem;
  }
}

%container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media #{$desktop} {
    padding-right: 5rem;
  }
  @media #{$desktop-xxl} {
    padding-right: 0;
  }
  @media #{$mobile} {
    flex-direction: column;
  }

  [dir='rtl'] & {
    @media #{$desktop} {
      padding-left: 5rem;
      padding-right: 0;
    }

    @media #{$desktop-xxl} {
      padding-left: 0;
    }
  }
}

.containerLeft {
  @extend %container;
}

.containerRight {
  @extend %container;
}

.imgContainer2 {
  background-image: url('#{$image-path}/about/about1.jpg');
  @extend %imgContainer;
  width: 30%;
  height: 20rem;
  margin: 5rem 3em 1.5rem 0;
  @media #{$mobile} {
    width: 100%;
    margin: 5rem 0 1.5rem 0;
  }

  [dir='rtl'] & {
    margin: 5rem 0 1.5rem 3em;
    @media #{$mobile} {
      margin: 5rem 0 1.5rem 0;
    }
  }
}

.imgContainer3 {
  background-image: url('#{$image-path}/about/about2.jpg');
  @extend %imgContainer;
  width: 30%;
  height: 20rem;
  margin: 1.5rem 0 5rem 3rem;
  @media #{$mobile} {
    width: 100%;
    margin: 5rem 0 1.5rem 0;
  }

  [dir='rtl'] & {
    margin: 1.5rem 3rem 5rem 0;
    @media #{$mobile} {
      margin: 5rem 0 1.5rem 0;
    }
  }
}
