@import '../../../scss/responsive.scss';

.uploadedFileWrapper {
  display: flex;
  align-items: center;
  margin-top: -3px;
  justify-content: space-between;
  border: solid 2px var(--semi-mid-tone);
  border-right: none;
  :global(.isRtl) & {
    border: solid 2px var(--semi-mid-tone);
    padding-right: 10px;
  }
}

.fileNameBox {
  width: 199px;
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.fileNameLabel {
  margin-left: 10px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  overflow: hidden;
  overflow-wrap: break-word;
}

.fileActions {
  display: flex;
  height: 52px;
  :global(.isRtl) & {
    flex-direction: row-reverse;
  }
}

.fileActionBtn {
  width: 46px;
  border: solid 2px var(--semi-mid-tone);
  background-color: var(--semi-mid-tone);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;

  &:disabled {
    opacity: 0.5;
  }
}

.progressFileUploadLine {
  height: 22px;
}
  
.uploadedFileBar {
  margin-bottom: 10px;
}

@media #{$phone}{
  .uploadedFileWrapper{
    height: 30px;
  }
  .fileNameBox {
    height: 30px;
  }
  .fileNameLabel {
    font-size: 12px;
  }
  .fileActions {
    height: 30px;
  }
}

:export {
  lineColor: var(--brand);
}
