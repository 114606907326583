@import 'scss/responsive.scss';

.container {
  border-radius: 2.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  margin-right: 3rem;

  @media #{$mobile} {
    margin-right: unset;
    margin-bottom: 3rem;
    height: 5rem;
  }

  :global(.isRtl) & {
    margin-right: unset;
    margin-left: 3rem;
  }

  .shareIcons {
    transition: width 1s, opacity 0.5s, visibility 1s ease-out;
    display: flex;
    justify-content: space-around;
    overflow: hidden;

    > button {
      display: flex;
      align-items: center;
    }
  }
}

.contractedContainer {
  cursor: pointer;
  width: 5rem !important;
}

.contractedShareIcons {
  width: 0;
  opacity: 0;
  visibility: hidden;
}

.shareLinkButton {
  width: 2rem;
  background-color: transparent;
  color: #fff;
  border-radius: 2.5rem;
  font-size: 1.6rem;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
}

.expandedShareIcons {
  width: 20rem;
  opacity: 1;
  visibility: visible;
  @media #{mobile} {
    width: 15rem;
  }
}

.showShareButton {
  width: 5rem;
  button {
    border: none;
    color: #fff;
    padding: 0;
  }

  @media #{$mobile} {
    width: 3rem;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 2rem;
  font-weight: 500;
  line-height: 3rem;
  text-align: center;
  transition: width 1s ease-in;
  width: 19.2rem;

  @media #{$mobile} {
    font-size: 1.5rem;
  }
}

:export {
  white: #fff;
  secondary1: var(--brand);
}
