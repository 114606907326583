@import '../../../scss/responsive.scss';

.questionContainer {
  position: relative;
  width: 100%;

  &.fullHeight {
    height: 100%;
  }

  &.disabled {
    display: none;
    height: 0;
  }

  &.disabledWithDisplay {
    > input {
      opacity: 0.5;
    }
  }

  &.rtl {
    direction: rtl;
  }

  :global(.isRtl) & {
    text-align: right;
    direction: rtl;
  }
}

.questionLabel {
  font-size: 1.6rem;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-bottom: 5px;
}

.questionDescription {
  color: var(--deeper-mid-tone);
  font-size: 1.4rem;
  font-weight: normal;
  margin-top: 2px;
}

.labelContainer {
  margin-bottom: 5px;
  position: relative;

  :global(.isRtl) & {
    direction: rtl;
    text-align: right;
  }
}

.logoTick {
  stroke: #000;
  position: absolute;
  top: 5px;
  left: -27px;

  &.disabled {
    display: none;
  }

  :global(.isRtl) & {
    left: initial;
    right: -27px;
  }
}

@media #{$phone} {
  .questionContainer {
    margin-left: 2px;
  }
  .labelContainer {
    margin-bottom: 5px;
  }
  .logoTick {
    left: -15px;
    top: 1px;
    :global(.isRtl) & {
      right: -15px;
    }
  }
}

.inline {
  display: flex;
}
