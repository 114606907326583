.container {
  display: flex;
  width: 100%;
}

.column {
  width: 50%;
  & table {
    width: 100%;
    text-align: left;
  }
}

.sectionHeading {
  font-weight: bold;
  padding: 1rem 0;
}
