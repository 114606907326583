@import '~scss/variables';
@import '~scss/typography.scss';
@import '~scss/responsive.scss';

.wrapper {
  &:not(:last-of-type) {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  :global(.isRtl) & {
    text-align: right;
    direction: rtl;
  }
}

.input {
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;

  &:focus {
    + :before {
      outline: 2px solid var(--brand);
    }
  }
}

.disabled {
  opacity: 0.5;
}

.errorMessage {
  color: var(--error);
}

.errorPlaceholder {
  height: 2.2rem;
}

.label {
  $size: 2.5rem;

  display: inline-flex;
  position: relative;
  height: $size;
  line-height: $size;
  font-size: $font-size-input;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    background-color: var(--background-accent);

    height: $size;
    width: $size;
    min-height: $size;
    min-width: $size;

    margin-right: 1.5rem;
    [dir='rtl'] &,
    :global(.isRtl) & {
      margin-right: 0.2rem;
      margin-left: 1.5rem;
    }
  }

  &.error {
    &:before {
      border: 1px solid var(--error);
    }
  }

  &.dark {
    &:before {
      background-color: var(--checkbox-background);
    }
  }

  .input[type='radio'] + & {
    &:before {
      border-radius: 100%;
    }
  }

  .input[type='checkbox']:checked + & {
    &:after {
      content: '';
      display: inline-block;

      position: absolute;
      top: 4px;
      left: 2px;

      height: $size;
      width: $size;

      background-image: var(--checkmark-icon);
      background-repeat: no-repeat;
      background-size: 80%;

      :global(.isRtl) & {
        left: initial;
      }
    }
  }

  .input[type='checkbox'][disabled]:checked + & {
    &:after {
      opacity: 0.5;
    }
  }

  .input[type='radio']:checked + & {
    &:before {
      border: solid 1.5px var(--black);
      -moz-border: solid 1.5px var(--black);
      -webkit-border: solid 1.5px var(--black);
    }

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      height: 1.5rem;
      width: 1.5rem;
      background-color: var(--primary);
      border-radius: 0.75rem;
      background-repeat: no-repeat;
      background-size: 100%;

      :global(.isRtl) & {
        left: initial;
        right: 0.7rem;
      }
    }
  }

  .input[type='radio'][disabled]:checked + & {
    &:after {
      opacity: 0.5;
    }
  }
}

@media #{$mobile} {
  .label {
    $size: 13px;
    &:before {
      height: $size;
      width: $size;
      min-height: $size;
      min-width: $size;
    }

    .input[type='radio']:checked + & {
      &:after {
        top: 4px;
        left: 4px;
        height: 8px;
        width: 8px;
        background-size: 64%;
        :global(.isRtl) & {
          left: initial;
          right: 2px;
        }
      }
    }
  }
}
