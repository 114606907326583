$cell-size: 24px;
$connect-line-width: 24px;
$line-width: 2px;

.container {
  display: flex;
  height: calc(#{$cell-size} * 2);
  align-items: center;
}

.icon {
  // no specific reasons for choosing this formular: it is just calibrated
  height: calc(#{$cell-size} / 2 + 4px);
  width: calc(#{$cell-size} / 2 + 4px);
}
.rejected {
  background-color: var(--error);
  border-color: var(--error) !important;
}
.accepted {
  background-color: var(--valid);
  border-color: var(--valid) !important;
}
.pending {
  background-color: var(--primary);
  border-color: var(--primary) !important;
}
.statusCell {
  height: $cell-size;
  width: $cell-size;
  border-radius: $cell-size;
  border: $line-width solid var(--deeper-mid-tone);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--background);
}

.connectLine {
  width: $connect-line-width;
  height: $line-width;
  background-color: var(--deeper-mid-tone);
}
