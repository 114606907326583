.formSection {
  margin-bottom: 4px;
}

.tooltip {
  position: absolute;
  bottom: 6rem;
}

.sendEmailContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.emailOptionsDropdown {
  width: 30rem;
}
