@import '../../scss/responsive.scss';

.item {
  display: flex;
  justify-content: space-between;
  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:nth-child(even) {
    background: var(--semi-mid-tone);
  }
}

.nameContainer {
  display: flex;
  .innerNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
}

.wrapper {
  background: var(--mid-tone);
  color: var(--dark-color-secondary);
  cursor: pointer;
  font-size: 1.2rem;
  width: 130px;
  padding: 1.1rem 1.6rem;
  padding-top: 14px;
  position: relative;
  flex: 1;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.existingRole {
  font-size: 1.5rem;
}
.dropdown {
  background: var(--mid-tone);
  border-radius: 8px;
  border: 3px solid var(--light-color-grey);
  font-style: normal;
  left: 0;
  list-style: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
  font-size: 1.5rem;
}

.role,
.disabledRole {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2.9rem;
}

.disabledRole {
  background: var(--semi-mid-tone);
  cursor: not-allowed;
}

.searchBarRow {
  // gives extra space between the search bar and the list so that the list don't over lap with the filters
  padding: 3rem 0;
  flex: 1;
}
