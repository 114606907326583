@import 'scss/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 106px $header-padding;
  background-color: var(--semi-mid-tone);
  width: 20vw;

  // box shadows
  -webkit-box-shadow: 8px 0px 5px -3px rgba(168, 166, 168, 1);
  -moz-box-shadow: 8px 0px 5px -3px rgba(168, 166, 168, 1);
  box-shadow: 8px 0px 5px -3px rgba(168, 166, 168, 1);

  overflow-y: auto;
  height: 100%;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settingsButton {
  cursor: pointer;
  font-size: 1.3rem;
}
