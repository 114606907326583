.reviewContent {
  display: flex;
  margin: 3rem 3rem 0rem 3rem;
  height: calc(100% - 3rem);
}

.sectionsColumn {
  width: 25rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--background-accent);
}

.columnR {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  border: 2px solid var(--contrast-mid-tone);
}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
