$asset-path: '~assets';
$font-path: '#{$asset-path}/fonts';
$icon-path: '#{$asset-path}/icons';
$image-path: '#{$asset-path}/images';

$border-layout: 4px solid var(--light-color-grey);
$header-height: 111px;
// distance between the bottom border of header and the side edge
$header-padding: 50px;
$header-padding-mobile: 20px;
$mobile-header-height: 70px;
$header-height-collapsed: 50px;
$mobile-header-height-collapsed: 50px;
$actionBar-height: 9rem;
$desktop-width: 1300px;
