@import './scss/responsive';
@import './scss/variables';

.skipNav {
  position: fixed;
  top: 0;
  left: 10px;
  z-index: 10000;
  font-size: 2.4rem;
  padding: 1rem;
  border-radius: 0 0 5px 5px;
  background-color: var(--brand);
  color: var(--brand-contrast);
  transform: translateY(-100%);
  transition: transform 1s ease-in-out;

  &:focus {
    transform: translateY(0);
  }
}

.ltr {
  display: flex;
  flex-direction: column;
  background: var(--background);
}

.content {
  overflow: auto;
  background: var(--background);
  padding-left: $header-padding;
  padding-right: $header-padding;
  min-height: 100vh;

  @media #{$mobile} {
    padding-left: $header-padding-mobile;
    padding-right: $header-padding-mobile;
  }
}

.logoContainer {
  // so to align horizontally with the header options next to it
  position: fixed;
  top: 3rem;
  display: flex;
  align-items: center;
  color: var(--background);
}

.withHeaderMargin {
  // this is to compensate content being hidden by the header
  margin-top: $header-height;
  transition: margin-top 0.5s ease;
  min-height: calc(100vh - #{$header-height});
  @media #{$mobile} {
    margin-top: $mobile-header-height;
    min-height: calc(100vh - #{$mobile-header-height});
  }
  &.collapse {
    min-height: calc(100vh - #{$header-height-collapsed});
    margin-top: $header-height-collapsed;
    @media #{$mobile} {
      margin-top: $mobile-header-height-collapsed;
    }
  }
}
.withActionBarMargin {
  margin-bottom: $actionBar-height;
}

.fullHeightContent {
  height: 100vh;
  overflow: auto;
}

.logo {
  cursor: pointer;
  z-index: 10;

  img {
    width: 23rem;
  }
  [dir='rtl'] & {
    left: 0;
  }
  [data-theme='dark'] & {
    filter: invert(1);
  }
}

.header {
  width: 100%;
  height: 7rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 998;

  &:not(.loggedOutHeader) {
    padding: 1.3rem 4rem 1.3rem;
    background: white;

    @media #{$mobile} {
      padding: 0;
      z-index: 2;
    }
    &.whiteBackground {
      background-color: var(--background);
    }
  }
}

.whiteBackground {
  background: var(--background);
  @media (max-width: 767px) {
    height: auto !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

.loggedOutHeader {
  height: 115px;
  padding: 40px 4vw;
  * > div {
    border: none;
  }
  // the items seen during loggout looks better when they are aligned center
  // since the class names are compiled from Header.module.scss
  // only the tags and wildcards are reliable ways to select inner elements here
  > header > * > * {
    align-items: center;
  }
}
.hide {
  display: none;
}

.challengesHeader {
  & + * {
    margin-top: $header-height;
  }
}

.inverted {
  filter: invert(100);
}
