@import '../mixins';
@import '../typography';
@import '../variables';

// Primary button
$button-width: 22.4rem;
$border-radius: 0px;
$button-padding: 1.9rem 1.6rem 1.6rem;
$button-background-color: transparent;
$button-font-color: var(--primary-tinted);
$button-font-weight: $font-weight-medium;
$button-font-size: 1.76rem;
$button-border-color: var(--primary);
$button-border: 3px solid $button-border-color;

// White button
$button-white-width: 270px;
$button-white-border: 3px solid white;
$button-white-padding: $button-padding;
$button-white-background-color: var(--background);
$button-white-border-radius: $border-radius;
$button-white-focus-color: var(--deeper-mid-tone);

// Secondary button
$button-inverse-background-color: var(--primary);
$button-inverse-background-image: linear-gradient(to right, #2377ac, var(--light-color-blue));
$button-inverse-font-color: var(--primary);
$button-inverse-border: $button-border;

// Red button
$button-red-background-color: transparent;
$button-red-font-color: var(--red-button-color);
$button-red-border: 3px solid var(--red-button-color);


$button-linkedin-padding: 1.5rem 1.6rem 1.1rem 1.6rem;
$button-linkedin-spacing: 1.5rem;

button,
.button {
  max-width: 100%;
  border: $button-border;
  border: $button-border;
  padding: $button-padding;
  background-color: $button-background-color;

  @media #{$mobile} {
    font-size: 2.4rem;
    line-height: 4rem;
    padding: 0rem;
  }

  cursor: pointer;
  transition: all 125ms;
  -webkit-appearance: none;

  color: $font-color-inverse;
  font-family: $font-family-base;
  font-weight: $button-font-weight;
  font-size: $button-font-size;

  &:disabled {
    cursor: not-allowed;
  }
}

.button--block {
  display: block;
  width: 100%;
}

.button--inverse {
  @extend .button;
  color: $button-inverse-font-color;
  border: $button-inverse-border;
  background-color: $button-inverse-background-color;

  &:focus {
    box-shadow: 0 0 1px 1px var(--brand-transparent);
  }
}

.button--red {
  @extend .button;
  color: $button-red-font-color;
  border: $button-red-border;
  background-color: $button-red-background-color;

  &:focus {
    box-shadow: 0 0 1px 1px var(--brand-transparent);
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.button--blue {
  @extend .button--inverse;
  border: none;

  background-image: $button-inverse-background-image;
  border: none;

  @media #{$mobile} {
    padding: 0.9rem 1.9rem;
    font-size: 2.4rem;
  }
}


.button--linkedin {
  @extend .button--inverse;
  border: none;
  
  display: flex;
  align-items: center;
  padding: $button-linkedin-padding;
  background-image: $button-inverse-background-image;

  font-weight: $font-weight-normal;
  font-size: 2.24em;
  background-color: transparent;


  body[dir="ltr"] &:before, 
  body[dir="rtl"] &::after {
    content: '';
    display: inline-block;

    position: relative;
    top: -2px;

    @media #{$mobile} {
      top: 0rem;
      
    }
    $size: 3.2rem;
    width: $size + $button-linkedin-spacing;
    height: $size;

    background-image: url('#{$image-path}/logos/linkedin.svg');
    background-repeat: no-repeat;
    background-size: contain;
    

    @include invert;

    padding-right: $button-linkedin-spacing;
    margin-right: $button-linkedin-spacing;
    border-right: 2px solid var(--primary-tinted);
  }
}

.button--white {
  width: $button-white-width;
  border: $button-white-border;
  padding: $button-white-padding;
  background-color: $button-white-background-color;
  border-radius: $button-white-border-radius;

  &:hover {
    transform: translateY(-3px);
  }

  &:focus, &:active {
    background-color: $button-white-focus-color;
    border-color: $button-white-focus-color;
  }
}

.button--white--inverse {
  @extend .button--white;

  color: $button-white-background-color;
  background-color: transparent;

  &:focus, &:active {
    color: $button-white-focus-color;
    background-color: transparent;
  }

  [data-theme='dark'] & {
    color: $button-font-color;
  }
}
