@import 'scss/variables.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-right: $header-padding;
  height: 100%;
  overflow-y: hidden;
  font-size: 1.6rem;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: calc(100vh - 29rem);
}

.tableContainer {
  background-color: var(--background-accent);
  flex: 1;
  max-height: calc(100% - 50px);
}

.noApplicationsFound {
  font-size: 2.5rem;
  font-weight: bold;
  margin-left: 40%;
  margin-top: -20%;
}

.spacer {
  height: 3rem;
}
