@import "../../scss/modules/layout.module";

.form {
  width: 90%;

  @media #{$desktop-xl} {
    width: 100%;
  }

  button {
    background-color: white;
    color: black;
    padding: 7px;
    border: 2px solid black;
    font-size: 20px;
    cursor: pointer;
    line-height: 5rem;

    &:hover {
      background-color: var(--footer-background);
      color: white;
      transition: all 0.4s
    }

    &:disabled, &:disabled:hover {
      color: var(--font-color-grey);
      background-color: var(--button-disabled-background);
      border-color: var(--font-color-grey);
      cursor: not-allowed;
    }
  }
}

.heading {
  font-size: 3rem;
}

.description {
  font-size: 2.5rem;
  line-height: 3.5rem;
}

.termsParagraph {
  position: relative;
  margin-top: 0;
  padding-bottom: 36px;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;

  @media #{$mobile} {
    margin: 1.2rem 0.6rem;
    padding-bottom: 0;
  }
}

.termsLabel {
  font-size: 1.52rem;
  line-height: 1.9rem;
  cursor: pointer;

  @media #{$mobile} {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}

.termsCheckbox {
  label {
    margin: 1rem 0;
    height: unset;
    a {
      font-weight: bold;
      text-decoration: none;
    }
  }
}
