.searchInput {
  max-width: 100%;
  background-color: var(--background-accent);
  box-shadow: none !important;
}

.searchBar {
  display: flex;
  background-color: var(--background-accent);
  > input {
    outline-offset: -2px;
  }
  > .existingFilter:nth-child(1) {
    margin-left: 16px;
  }
}

.existingFilter {
  border-radius: 8px;
  margin: 8px;
  padding: 8px 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  background-color: var(--background);

  p {
    margin-right: 8px;
  }
  > :not(p) {
    cursor: pointer;
  }
}

.helpBox {
  position: fixed;
  display: flex;
  z-index: 999;
  flex-direction: column;
  border: 1px solid var(--semi-mid-tone);
  background-color: var(--background-accent);
  :nth-child(1) {
    margin-left: 0px;
  }
}

.helpItem {
  cursor: pointer;
  padding: 8px;
  &.highlit {
    background-color: var(--semi-mid-tone);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
