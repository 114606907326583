.button {
  align-items: center;
  appearance: none;
  background: var(--dark-grey-primary);
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
}
