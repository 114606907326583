@import '../../../scss/responsive.scss';

.sectionTitleTabLabel {
  height: 100%;
  padding-right: 5px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  overflow-x: hidden;
  text-overflow: ellipsis;

  flex: 1;
  font-size: 1.8rem;

  :global(.isRtl) & {
    direction: rtl;
    text-align: right;
  }
}

.tooltip {
  position: absolute !important;
  top: -10px;
}

.sectionScore {
  width: 6rem;
  height: 6rem;
  border: solid 3px var(--contrast-mid-tone);
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;

  :global(.isRtl) & {
    border-right: none;
    border-left: solid 3px var(--contrast-mid-tone);
  }
}

%sectionTitleLogo {
  /* size */
  width: 2vw;
  height: 2vw;

  /* position */
  position: absolute;
  top: -1vw;
  left: -1vw;

  :global(.isRtl) & {
    left: initial;
    right: -1vw;
  }
}

.sectionTitleLogoRemove {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  left: -40px;

  :global(.isRtl) & {
    left: initial;
    right: -40px;
  }
}

.sectionTitleLogoComplete {
  @extend %sectionTitleLogo;
}

.sectionTitleLogoPending {
  @extend %sectionTitleLogo;
  display: none;
}

.sectionTitleTab {
  font-size: 1.8rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-wrap: break-word;
  min-height: 6rem;
  cursor: pointer;
  margin-right: -5px; // only show the right border in case there is no next element on the right side (short sections)
  margin-bottom: 2rem;
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  :global(.isRtl) & {
    flex-direction: row-reverse;
    margin-right: 0;
    margin-left: -5px;
  }
}

.sectionTitleTabActive {
  background: var(--background);
  color: var(--brand);
  border: 2px solid var(--contrast-mid-tone);
  border-right: 0;
  position: relative;
  label {
    // 10 - 4 = 6...
    padding-left: 6px;
  }
  &:after {
    content: '';
    display: block;
    width: 5px;
    position: absolute;
    right: -3px;
    height: 100%;
    background: var(--background);
  }
}

.sectionTitleTabInactive {
  background: var(--contrast-mid-tone);
  [data-theme='dark'] & {
    background: var(--dark-background-tab);
  }
}

.disabled {
  cursor: not-allowed;
}

.sectionTabWrapper {
  margin: 4px 0px;
  margin-right: 5px;
  width: 100%;
  box-sizing: border-box;

  :global(.isRtl) & {
    margin-right: 0;
    margin-left: 5px;
  }
}

.sectionIndicator {
  z-index: 2;
  height: 0;
  position: relative;
  list-style: none;
  display: block;

  :global(.isRtl) & {
    right: 0;
  }
}

@media #{$medium} {
  .sectionTitleLogoComplete,
  .sectionTitleLogoPending,
  .sectionTitleLogoRemove {
    :global(.isRtl) & {
      right: unset;
    }
  }
}

@media #{$desktop-sm} {
  .sectionTitleLogoComplete,
  .sectionTitleLogoPending,
  .sectionTitleLogoRemove {
    :global(.isRtl) & {
      right: -15px;
      left: initial;
    }
  }
}

@media #{$phone} {
  .sectionScore {
    height: 6rem;
    width: 6rem;
    min-width: 6rem;
  }

  .sectionTitleTabActive,
  .sectionTitleTabInactive {
    min-height: 6rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .sectionTitleLogoComplete,
  .sectionTitleLogoPending,
  .sectionTitleLogoRemove {
    height: 1em;
    width: 1em;
    top: -5px;

    :global(.isRtl) & {
      left: initial;
    }
  }
}

:export {
  black: var(--primary);
  white: var(--background);
  secondary: var(--brand);
}
