@import "../../../scss/variables.scss";
@import "../../../scss/modules/layout.module.scss";
@import "../../../scss/responsive.scss";

.slider {
  @include main-slider;
  @media #{$mobile} {
    height: 100vh !important;
  }
}

.background {
  @include main-background;
  animation: shrink 35s ease-out infinite alternate;
  animation-delay: -4s;
  z-index: -1;
  overflow-x: hidden;
  will-change: opacity;
}

.preloadBackground {
  z-index: -10;
  opacity: 0;
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }

  3% {
    transform: scale(1);
  }

  97% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1.3);
  }
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 14vh 4vw;

  @media #{$mobile} {
    justify-content: start;
    padding: 0 6vw;
    padding-top: 16vh;
  }

  h1 {
    color: var(--brand-contrast);
  }
}

.headingContainer {
  width: 100%;
  max-width: 900px;

  @media #{$mobile} {
    top: 40%;
    height: 15rem;
    text-align: center;
  }
}

.heading {
  font-size: 7.5rem;
  font-weight: 500;
  margin-bottom: 4rem;
  text-transform: uppercase;
  white-space: pre-wrap;
  color: var(--brand-contrast);

  span {
    display: inline-block;
  }

  @media #{$mobile} {
    font-size: 5rem;
    line-height: 5.6rem;
    height: 10.8rem;
    span {
      display: block;
    }
  }
}

.descriptionContainer {
  width: 100%;
  max-width: 900px;

  @media #{$mobile} {
    margin-top: 15vh;
  }
}

.description {
  font-size: 2.4rem;
  line-height: 3.6rem;
  white-space: pre-wrap;
  color: var(--brand-contrast);
  
  @media #{$mobile} {
    font-size: 2.6rem;
    line-height: 4rem;
  }
}

.photoCredit {
  color: var(--brand-accent);
  position: absolute;
  left: 5px;
  bottom: 5px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  font-size: 10px;
  line-height: 10px;
  :global(.isRtl) & {
    right: 5px;
    left: unset;
  }
}

.sliderContainer {
  height: 100%;
}
