.breadcrumbs {
  margin: 1rem 0;
  font-size: 1.6rem;
  > :not(:first-child) {
    &::before {
      content: '›';
      padding: 0px 10px;
      text-decoration: none !important;
    }
  }
  * > img {
    height: 1.6rem;
    width: 1.6rem;
  }

  line-height: 2.3rem;
  font-family: 'Neo Sans Arabic', Helvetica, Arial, sans-serif;
  a {
    color: inherit;
    text-decoration: none;
  }
}

.bold {
  font-weight: bold;
}
