@import '../../../scss/typography';
@import '../../../scss/variables.scss';

.container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 3.17rem;
  height: 100%;
  line-height: 6rem;
  a {
    color: var(--brand);
    text-decoration: none;
  }
}
