.container {
  display: flex;
  margin: 3rem 3rem 0rem 3rem;
  height: calc(100% - 3rem);
}
.socialMediaContainer {
  display: flex;
  [data-theme='dark'] & {
    filter: invert(1);
  }
}

.socialMedia {
  height: 24px;
  width: 24px;
  padding: 4px;
  cursor: pointer;
}
.sectionsColumn {
  width: 25rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.columnR {
  flex: 1;
  padding: 0px 32px;
  height: 100%;
  overflow-y: auto;
  padding-top: 42px;
  padding-left: 92px;
}

.column {
  display: flex;
  flex-direction: column;
}

.dateAndGoldenTicketContainer {
  display: flex;
  flex-direction: column;
  > div {
    flex: 1;
  }
}
