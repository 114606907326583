.container {
  box-sizing: border-box;
  min-width: 63.4rem;
  padding: 0 12rem;
}

.title {
  color: var(--primary);
  font-size: 2.5rem;
  margin-top: 5rem;
  text-align: center;
  max-width: 70rem;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  border: 2px solid var(--primary);
  font-size: 1.7rem;
  margin-top: 2.8rem;
  width: 16rem;
  margin-right: 1.6rem;
}
