@import '../../scss/responsive.scss';

.container {
  display: flex;
}

.disabled {
  cursor: disabled;
  opacity: 0.5;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--primary);
  color: var(--primary);
  padding: 0.2rem 1.7rem 0.2rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 4.455rem;
  text-align: center;
  cursor: pointer;

  &.selected {
    background-color: var(--primary);
    color: var(--background);
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  @media #{$phone} {
    width: auto !important;
  }
}
