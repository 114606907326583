@import '../typography';
@import '../responsive.scss';

// Input properties
$input-width: 100%;
$input-max-width: 100%;
$input-padding: 0.6rem 1.7rem 0.2rem;
$input-margin: 0;
$input-background-color: var(--semi-mid-tone);
$input-border: 2px solid transparent;
$input-border-radius: 1.4px;
$input-transition: 0.1s ease-in-out all;
$input-font-color: var(--primary);
$input-font-size: 1.56rem;

// Select properties
$select-height: 4.54rem;
$select-width: 100%;
$select-max-width: 100%;
$select-padding: $input-padding;
$select-margin: 0;
$select-background-color: var(--semi-mid-tone);
$select-border: 2px solid transparent;
$select-border-radius: 1.4px;
$select-transition: 0.1s ease-in-out all;
$select-font-color: var(--primary);
$select-font-size: 1.56rem;
$dropdown-arrow: url('/src/assets/icons/dropdownArrow.svg');

input,
textarea,
select {
  font-family: $font-family-base;

  &:focus:not(:read-only):not(:disabled) {
    outline: 2px solid var(--brand);
  }
}

input {
  width: $input-width;
  max-width: $input-max-width;
  padding: $input-padding;
  margin: $input-margin;
  background: $input-background-color;
  border: $input-border;
  border-radius: $input-border-radius;
  transition: $input-transition;
  color: $input-font-color;
  font-size: $input-font-size;

  &[type='date'] {
    // fix ugly date inputs on iOS
    @media #{$mobile} {
      -webkit-appearance: none;
    }
  }
}

select {
  height: $select-height;
  width: $select-width;
  max-width: $select-max-width;
  &:not(.react-datepicker__month-select):not(.react-datepicker__year-select) {
    padding: $select-padding;
    margin: $select-margin;
    background: $select-background-color;
    border: $select-border;
    border-radius: $select-border-radius;
    transition: $select-transition;
    color: $select-font-color;
    font-size: $select-font-size;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    &:not:disabled {
      cursor: pointer;
    }
    &:disabled {
      opacity: 0.5;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $select-font-color;
      transition: color 0ms;
    }
  }
}

.selectWrapper {
  position: relative;
  max-width: $select-max-width;
  &:not(.readOnly) {
    &:before {
      content: $dropdown-arrow;
      position: absolute;
      right: 2rem;
      [dir='rtl'] & {
        right: unset;
        left: 2rem;
      }
      transform: translateY(-50%);
      top: 50%;
      pointer-events: none;
    }
  }

  select {
    padding-right: 5rem;
  }
}

.inputGroups {
  margin-bottom: 14px;
}

.inputGroup {
  position: relative;

  & + & {
    margin-top: 0.6em;

    @media #{$mobile} {
      margin-left: 0;
    }
  }

  label {
    display: block;
    margin-bottom: 0.35em;
  }

  input {
    width: 100%;

    &.noValidation {
      margin-bottom: 1.6rem;
    }

    &.doubleValidationLine {
      margin-bottom: 6rem;
    }
  }

  @media #{$mobile} {
    // margin-left: 3rem;

    label {
      font-size: 2.5rem;
    }

    input {
      font-size: 2.5rem;
    }
  }
}

.inputError {
  border-color: var(--error);

  &:focus {
    box-shadow: 0 0 1px 0 var(--error-transparent);
  }
}

.inputErrorLabel {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  color: var(--error);
  font-size: 1.44rem;
  line-height: 2.2rem;

  img {
    position: relative;
    top: 2px;
    display: inline;
    height: 1em;
    margin-right: 8px;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  @media #{$mobile} {
    font-size: 2rem;
    align-items: center;
    // TODO: check error labels.
    position: unset;
    left: unset;
    right: unset;
    margin-top: -1.5rem;
    margin-bottom: 3rem;
  }
}

.inputValid {
  border-color: var(--valid);

  &:focus {
    box-shadow: 0 0 1px 0 var(--valid-transparent);
  }
}
