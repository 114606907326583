@import '../../scss/mixins';
@import '../../scss/typography';
@import '../../scss/zindex';
@import '../../scss/responsive.scss';
.footer {
  z-index: $z-index-footer;
  background: var(--footer-background);
  color: var(--footer-text);
  height: 140px;
  font-size: 14px;
  padding: 0 5%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media #{$mobile} {
    flex-direction: column;
    height: 35vh;
  }
}

.govLinkContainer {
  > div {
    color: var(--footer-title);
  }
  > a {
    &:first-child {
      border-right: 1px solid var(--footer-title);
      padding-right: 8px;
      margin-right: 8px;
    }
  }
}
.logo {
  > img {
    height: 50px;
    filter: invert(1);
  }
}

.links {
  @include reset-list-style;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  li {
    padding: 0 20px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
    font-size: 13px;
    pointer-events: auto;
    color: var(--footer-text);
  }

  .copyright {
    color: var(--footer-title);
  }
  @media #{$mobile} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 32vh; // target height = 32vh, vs 35vh of the container,

    li {
      flex: 1;
      width: 100%;
      padding: 0;
      a {
        font-size: 2.2rem;
        color: var(--brand-contrast);
      }
    }
  }
}

@media #{$mobile} {
  .footer {
    padding: 5%;
  }
}

@media #{$desktop} {
  .smallLink {
    display: none;
  }
}

.linksInverseColor {
  a {
    color: var(--primary-tinted);
  }

  .highlight {
    color: var(--primary-tinted);
  }
}

.hidden {
  visibility: hidden;
}
