.root {
  color: var(--deeper-mid-tone);
  font-size: 1.6rem;
  width: auto;
  display: flex;
  border: 0;
  text-decoration: underline;
  padding: 1.5rem 0;
  &:focus {
    box-shadow: none;
    border-color: transparent;
  }
}
