@import "../typography";
@import "../variables";
@import "../responsive.scss";

$area-right-background-color: var(--background);
$area-right-color: var(--primary-tinted);
$area-right-absolute-top: 40px;
$area-right-absolute-side: 50px;

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;

  [dir="rtl"] & {
    direction: rtl;
  }

  @media #{$mobile} {
    display: block;
    flex-direction: column;
    height: 100%;
  }
}

@mixin main-background {
  background-image: url("#{$image-path}/backgrounds/main.jpg");
  @media #{$mobile} {
    background-image: url("#{$image-path}/backgrounds/main-s.jpg");
  }

  background-position: center top;
  background-size: cover;

  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@mixin main-slider {
  &:before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(var(--brand), 0.45);
    background-color: rgba(0, 0, 0, 0.45);
    // background: linear-gradient(right, rgba(0, 159, 216, 0.65), rgba(0, 129, 195, 0.65), rgba(0, 101, 176, 0.65));
    mix-blend-mode: normal;
  }
}

.mainBackground {
  @include main-slider;
}

.areaFullInner {
  display: flex;
  justify-content: center;

  position: fixed;
  top: 12vh;
  left: 4vw;
  right: 4vw;
  bottom: 0;

  overflow: auto;
}

@mixin area {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media #{$mobile} {
    width: 100%;
  }
}

.areaLeft {
  @include area;

  position: relative;
  padding: 4vh 4vw 10vh 10vw;
  [dir="rtl"] & {
    padding: 4vh 10vw 10vh 4vw;
  }

  min-height: 25vh;
  h2 {
    font-size: 6.4rem;
    line-height: 7.68rem;
  }

  @media #{$mobile} {
    width: 0%;
    padding: 0px 0px;
    display: none;
  }
}

.areaRight {
  @include area;

  position: relative;
  padding: 8vh 8vw 6vh;
  background: $area-right-background-color;

  & * {
    color: $area-right-color;
  }

  h2 {
    font-size: 5.12rem;
    line-height: 6.14rem;
    margin-bottom: 4.2496rem;
  }

  button {
    width: 100%;
    justify-content: center;
  }

  @media #{$mobile} {
    width: 100%;
    padding: 0 8vw;
    height: 100%;
    overflow-y: unset;
    justify-content: flex-start;

    h2 {
      font-size: 3.6rem;
      margin-bottom: 2.5rem;
    }
  }
}

@mixin areaTop {
  position: absolute;
  top: $area-right-absolute-top;
  font-size: 1.76rem;
}

.areaTopLeft {
  @include areaTop;
  left: $area-right-absolute-side;

  [dir="rtl"] & {
    left: initial;
    right: $area-right-absolute-side;
  }
}

.areaTopRight {
  @include areaTop;
  right: $area-right-absolute-side;

  @media #{$mobile} {
    font-size: 2.2rem;
    z-index: 100;
    right: 4rem;

    a {
      color: var(--light-color-blue);
    }
  }

  [dir="rtl"] & {
    left: $area-right-absolute-side;
    right: initial;

    @media #{$mobile} {
      left: 4rem;
    }
  }
}

.contentFullContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1020px;
  margin: 0 20px;
  overflow: auto;

  @media #{$mobile} {
    width: unset;
    height: 100%;
    overflow-y: scroll;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  @media #{$mobile} {
    width: 65vw;
    align-self: center;
    position: absolute;
    top: 25vh;
  }
}

.formContainer {
  width: 100%;
  max-width: 495px;

  @media #{$mobile} {
    //  padding-left: 2rem;
    position: absolute;
    align-self: center;
    top: 12vh;
    width: 60vw;
  }
}

.arrow {
  z-index: 1;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  font-size: 3rem;
  color: $font-color-inverse;

  cursor: pointer;
}

.sliderBarArrowLeft {
  // left: 2rem;
  top: calc(50% - 5rem);
  [dir="rtl"] & {
    transform: rotate(180deg);
  }
}

.sliderBarArrowRight {
  // right: 2rem;
  top: calc(50% - 5rem);
  [dir="rtl"] & {
    transform: rotate(180deg);
  }
}
.sliderBarArrowLeft,
.sliderBarArrowRight {
  img {
    width: 1.2rem;
    height: auto;
  }
}

.sliderBar {
  position: relative;
  width: 6rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  background-color: var(--background);
  &.slideBarGray {
    background-color: var(--semi-mid-tone);
  }

  &:not(.slideBarGray) {
    box-shadow: 0 2px 6px 1px rgba(158, 158, 158, 0.5);
    z-index: 2;
  }

  @media #{$mobile} {
    width: unset;
    display: none;
  }
}

.sliderBarText {
  position: absolute;
  bottom: 85px;
  left: 0;
  right: 0;
  margin-left: 0.6rem;

  display: flex;
  align-items: center;

  font-size: 1.4rem;
  color: $font-color-inverse;
  text-transform: uppercase;

  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.sliderBarNumber {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2rem;
  color: $font-color-inverse;
  text-transform: uppercase;
}
