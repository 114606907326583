@import '../../../scss/variables.scss';

.actionBar {
  height: $actionBar-height;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 3rem;
  @media (max-width: 430px) {
    padding: 1.5rem 1rem;
  }
  background: var(--background);
  border-top: 3px solid var(--semi-mid-tone);
  flex-grow: 1;

  display: flex;
  justify-content: center;

  z-index: 100;
  box-shadow: -1px 5px 10px 0px rgba(0, 0, 0, 0.75);
}

.actionBarContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
  @media (max-width: 430px) {
    padding-left: 0rem;
  }

  :global(.isRtl) & {
    flex-direction: row-reverse;
  }
}

.innerContainer {
  width: $desktop-width;
  margin: auto;
}

.flexEnd {
  justify-content: flex-end;
}
