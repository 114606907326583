@import '../../../scss/responsive.scss';

.errorMessage {
  color: var(--error);
}

.errorPlaceholder {
  height: 2.2rem;
}

@media #{$phone} {
  .inputBoxOuter {
    width: 100%;
  }
}
