@import '../../scss/responsive.scss';

.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem 0;
  padding-top: 24px;
  @media #{$mobile} {
    width: 100%;
  }
}

.manageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.headlines {
  display: flex;
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.headline {
  font-size: 2.6rem;
}

.subHeadline {
  font-size: 2rem;
}
