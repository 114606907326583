@import 'scss/responsive';
@import 'scss/variables';

.tableContainer {
  width: 90%;
  max-width: 1500px;
  margin: auto;
}

.emptyMessage {
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  color: var(--deeper-mid-tone);
  div {
    margin-left: 8px;
  }
}

.table {
  /* These styles are suggested for the table fill all available space in its containing element */
  display: flex;
  flex-direction: column;
  /* These styles are required for a horizontaly scrollable table overflow */
  height: 100%;
  min-width: unset !important;
  border-spacing: 0;
  & thead {
    /* These styles are required for a scrollable body to align with the header properly */
    overflow-y: auto;
    overflow-x: hidden;
  }

  & a {
    text-decoration: none;
  }
}

.th.alignRightColumn,
.td.alignRightColumn {
  text-align: right;
  justify-content: flex-end;
}

.tbody {
  overflow-y: auto;
  overflow-x: visible;
  min-width: max-content;
  height: calc(100% - 6rem);
  &:has(.emptyMessage) {
    height: 100%;
  }
}

.innerTableContainer {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: hidden;
}

.tr {
  &:hover {
    .td {
      background-color: var(--brand-transparent-tint-subtle);
    }
    a {
      text-decoration: underline;
    }

    .showOnHover {
      opacity: 1;
    }
  }

  .showOnHover {
    opacity: 0;
  }
}

.headerRow {
  &:first-child {
    height: 0;
    border: 0;
  }

  &:hover {
    background-color: unset;
  }
}

.tbody .tr:nth-child(odd) {
  .td {
    background-color: var(--background-accent);
  }
  &:hover {
    .td {
      background-color: var(--brand-transparent-tint-subtle);
    }
  }
}

.th,
.td {
  margin: 0;
  padding: 2rem 1rem;
  display: flex;
  flex: 1;

  /*
    In this example we use an absolutely position resizer,
    so this is required.
  */
  position: relative;
  word-break: break-word;

  &:last-child {
    border-right: 0;
  }
}
.th {
  font-size: 1.7rem;
  flex-direction: column;
  color: var(--deeper-mid-tone);
  justify-content: center;
  align-items: flex-start;
  > span {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.arrow {
  display: inline-block;
  margin-left: 1rem;
}

.resizer {
  right: -5px;
  background: transparent;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  /* prevents from scrolling while dragging on touch devices */
  touch-action: none;

  &.isResizing {
    background: red;
  }
}

.headline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 2rem;
  & .more {
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    line-height: 1.7rem;
    color: var(--brand);
    font-weight: normal;
  }
}

.addButton {
  cursor: pointer;
  font-size: 14px;
  padding: 0 1.7rem 0 !important;
  line-height: 3.3rem;
}

.clickableIcon {
  cursor: pointer;
  margin: 0 8px;
}

.modeButton {
  border: none;
  padding: 0;
  margin: 2rem;
  font-weight: normal;

  &.active {
    border-bottom: 1px solid var(--brand);
    font-weight: bold;
  }
}

// props for the action icon group
.actionIconGroupContainer {
  display: flex;
  justify-content: space-evenly;
}
