.paginationContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.linkContainer {
  font-size: 1.5rem;
  display: flex;
  width: 25%;
  justify-content: space-between;
  margin-top: 10px;
}

.link {
  // offset to force the number to be at the center (approximately) of the div / span
  padding-left: 2px;
  padding-top: 4px;

  cursor: pointer;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--deeper-mid-tone);
  font-weight: lighter;

  &.disabled {
    opacity: 0.5;
  }

  &.active {
    color: var(--brand-contrast);
    border-radius: 16px;
    background-color: var(--brand-accent);
  }
}
