@import '../../../../scss/typography.scss';
@import '../../../../scss/responsive.scss';

.button {
  border: solid 2px var(--semi-mid-tone);
  background-color: var(--background);
  padding: 0.2rem 1.7rem 0.2rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 4.455rem;
  text-align: center;
  cursor: pointer;


  &:hover {
    background: var(--semi-mid-tone);
  }

  @media #{$phone} {
    font-size: 2rem;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  &.solid {
    opacity: 0.21;
  }
}
.primary {
  color: var(--primary);

  border-color: var(--primary);
  background-color: var(--background);

  &:hover {
    background: var(--background);
    color: var(--primary);
  }
}

.solid {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--background);
  padding: 0;

  &:hover {
    background: var(--deeper-mid-tone);
    border-color: var(--deeper-mid-tone);
  }

}

.border {
  border: 2px solid var(--primary);
  color: var(--primary);
  background-color: var(--background);

  padding: 0.2rem 1.7rem 0.2rem;

  &:hover {
    background: var(--semi-mid-tone);
  }

}
