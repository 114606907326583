.dropdownMenu {
  display: none;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  background: var(--background);
  top: 50px;
  left: 0;
  right: 0;
  border: 1px solid var(--border);
  z-index: 10;
  &:hover {
    display: block;
  }

  &.forcedOpen {
    display: block;
  }

  @media only screen and (max-width: 960px) {
    text-align: left;
    /* border: 1px solid #ccc; */
    border: 1px solid var(--border);
    list-style: none;
    /* background: #fff; */
    background: var(--background);
    position: absolute;
    right: -65px;
    top: 18px;
    width: 208px;
    margin-top: 20px;
    z-index: 20;
    left: unset;
  }
}

@mixin button {
  height: 50px;
  min-width: 50px;
  padding: 0;
  margin: 0;
  margin-bottom: -1px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid var(--border);
  background: var(--background);
  font-family: Roboto-Bold, sans-serif;
  font-size: 14.667px;
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    border-right: none;
  }
  &:hover {
    background-color: var(--primary-transparent);
  }
}

.link {
  @include button;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: var(--primary);
  text-decoration: none;
  font-size: 13px;
  text-transform: uppercase;
  padding: 20px;
  @media only screen and (max-width: 960px) {
    width: 100%;
    margin-top: 20px;
  }
}

.themeButton {
  @include button;
  padding: 0;
  background: var(--primary);
  border: none;
  svg {
    overflow: visible;
    stroke-width: 0.6px;
  }
  path {
    fill: var(--background);
    stroke: var(--background);
  }
  @media only screen and (max-width: 960px) {
    max-width: 40px;
    min-width: 40px;
    height: 40px;
  }
  &:hover {
    background-color: var(--primary);
  }
}

.languageButton {
  @include button;
  border-left: none;
  border-right: none;
  color: var(--primary);
  @media only screen and (max-width: 960px) {
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    border-left: 1px solid var(--border);
  }
}

.profileButton {
  @include button;
  path {
    fill: var(--primary);
  }
  @media only screen and (max-width: 960px) {
    max-width: 40px;
    min-width: 40px;
    height: 40px;
  }
  &:hover + .dropdownMenu {
    display: block;
  }
}

.menuTrigger {
  display: none;
  @media only screen and (max-width: 960px) {
    display: flex;
  }
}
