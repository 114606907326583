$font-size: 1.5rem;

.container {
  margin: 16px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerAllApplications {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--mid-tone);
}
.checkButton {
  font-size: 22px;
  color: var(--semi-mid-tone);
  border: 2px solid var(--brand);
}

.checkButtonSelected {
  color: var(--brand);
  border: none;
}

.stageName {
  padding-left: 16px;
  font-size: $font-size;
  flex: 1;
}

.count {
  font-size: $font-size;
}
