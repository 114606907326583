@import '../../scss/responsive.scss';

.container {
  display: flex;
  padding: 5rem 4rem;
  width: 100%;
  margin: 1.5rem;
  height: 18rem;
  background-color: var(--background);

  @media #{$mobile} {
    margin: 1.5rem 0;
  }

  cursor: pointer;
  &:hover {
    background-color: var(--semi-mid-tone);
  }
}

.image {
  width: 8rem;
  height: 8rem;
  background-color: var(--brand);
  padding: 1.5rem;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 2rem;
  position: relative;
  height: 80px;
}

.number {
  color: var(--brand);
  font-weight: bold;
  font-size: 2.4rem;
  margin-top: 1rem;
}

.label {
  color: var(--deeper-mid-tone);
  font-size: 1.6rem;
  margin-top: 1rem;
}
