@import '../../scss/mixins.scss';
@import '../../scss/responsive.scss';
@import '../../scss/variables.scss';

.container {
  margin-top: 10rem;
  text-align: left !important;
  direction: ltr !important;
  font-size: 1.9rem;
  line-height: 2.6rem;
  height: unset;

  p {
    margin: 2rem 0;
  }

  ul {
    padding-left: 4rem;
  }

  * {
    text-align: left !important;
    direction: ltr !important;
  }
}

.tnc {
  margin-top: -1rem;
}
.alignCenter {
  text-align: center;
}

.alignJustify {
  text-align: justify;
}
