@import 'scss/responsive.scss';

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  @media #{$mobile} {
    display: none;
    width: 0;
    height: 0;
  }
  // padding-left: .4rem;
}
.img {
  border-radius: 100%;
  height: 3.4rem;
  width: 3.4rem;
  object-fit: cover;
}

.imageBox {
  align-items: center;
  background: var(--brand);
  border-radius: 100%;
  color: var(--brand-contrast);
  display: flex;
  height: 3.4rem;
  justify-content: center;
  width: 3.4rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  @media #{$mobile} {
    display: none;
    width: 0;
    height: 0;
  }
}

.letter {
  font-size: 1.2rem;
  padding: 0.01rem;
}

.name,
.email,
.status,
.role {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
}

.highlight {
  font-weight: bold;
}
