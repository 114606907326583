.overlay {
  // making sure that the overlay is covering the whole page
  z-index: 9999999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  // making sure that the popup is at the center of the entire overlay

  display: flex;
  justify-content: center;
  align-items: center;
}

.cancelButtonContainer {
  height: 6rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--darker-mid-tone);
  padding-right: 2rem;
  .cancelButton {
    font-size: 20px;
    cursor: pointer;
  }
}

.container {
  width: 50vw;
  background-color: var(--semi-mid-tone);
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
}

.contentContainer {
  flex: 1;
  overflow: auto;
  padding: 0 4rem;
  p {
    font-size: 1.7rem;
  }
}

.actionRow {
  border-top: 2px solid var(--semi-mid-tone);
  height: 10rem;
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 4rem;
}

.agreeButton {
  font-family: 'Neo Sans Arabic' !important;
}
