@import '../../../scss/responsive.scss';

.dragAndDropBox{
  display: flex;
  height: 11rem;
  justify-content: space-between;
  align-items: center;
  border: solid 2px var(--semi-mid-tone);

  &.error {
    border: solid 2px var(--error);
  }

  :global(.isRtl) & {
    flex-direction: row-reverse;
    text-align: right;
    direction: rtl;
  }
}

.dragAndDropLabelWrapper{
  height: 11rem;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
}

.dragAndDropLabel{
  align-self: center;
  max-height: 4rem;
  max-width: 12rem;
  height: 4rem;
  font-size: 1.4rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.supportedFileList {
  max-width: 30rem;
  padding-left: 1rem;

  :global(.isRtl) & {
    padding-right: 1rem;
    padding-left: 0;
  }
}

.dragAndDropIconWrapper{
  width: 9rem;
  height: 10.6rem;
  border: solid 2px var(--mid-tone);
  background-color: var(--mid-tone);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragAndDropIcon{
  width: 5.5rem;
  height: 3.5rem;
}

@media #{$phone} {
  .dragAndDropBox{
    width: 100%;
  }

  .supportedFileList {
    max-width: 80%;
  }
}
