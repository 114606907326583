.logoWrapper {
  display: flex;
  align-items: center;
  padding-left: 0px;
  margin: 00px;
  margin-bottom: 30px;
  flex: 1;
  flex-shrink: 0;
  & a {
    color: var(--primary);
    text-decoration: none;
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
  }
  & svg {
    transform: scaleX(-1);
    width: 50px;
    height: 40px;

    &.rtl {
      transform: unset;
    }

    &.scrolledDown {
      width: 40px;
      height: 30px;
    }

    @media only screen and (max-width: 1422px) {
      width: 40px;
      height: 30px;
    }

    @media only screen and (max-width: 1313px) {
      display: none;
    }
  }

  @media only screen and (max-width: 960px) {
    margin: 0rem auto;
    justify-content: center;
  }

  &.menuOpen {
    display: none;
  }

  // Equivalent to invert of FIDs offset prop
  @media only screen and (max-width: 960px) {
    margin-bottom: unset;
  }
  &.scrolledDown {
    margin-bottom: unset;
  }
}

.logo {
  height: 50px;
  transition: 0.3s height ease;
  @media only screen and (max-width: 1313px) {
    max-height: 34px;
  }

  &.scrolledDown {
    height: 30px;
  }

  &.dark {
    filter: invert(1);
  }
}

.returnLabel {
  color: var(--primary);
  text-decoration: none;
  font-size: 13px;
  text-transform: uppercase;
  padding: 20px 20px 20px 0;
  position: relative;
  font-family: Roboto, sans-serif;
  transition: 0.3s font-size ease;
  @media only screen and (max-width: 1313px) {
    display: none;
  }

  &.scrolledDown {
    font-size: 10px;
  }
}
