.footerSpan {
  flex-grow: 1;
  color: var(--brand);
  text-align: center;
}

.actionButton {
  margin: 0 1rem;
}

.container {
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-grow: 1;
}

.publishButton {
  background: var(--primary) !important;
  color: var(--background) !important;
}
