.criticalContainer {
  display: flex;
  width: 120px;
  justify-content: space-between;
  align-items: center;
}

.critical {
  color: red;

  a {
    color: red;
  }
}
