.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0;
}

.content {
  display: flex;
  padding: 32px 0;
  height: calc(100% - 8rem);
}

.mainSection {
  flex: 2;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
}

.scoreSection {
  flex: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.toApplications {
  font-size: 1.6rem;
  color: var(--deeper-mid-tone);
}
