.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  font-size: 1.6rem;
}

.title {
  font-size: 1.6rem;
  padding-bottom: 8px;
  font-weight: bold;
  border-bottom: 3px solid var(--background-accent);
}

.content {
  flex: 1;
  display: flex;

  padding-top: 16px;
  justify-content: space-between;
  padding-right: 92px;
}

.subsectionTitle {
  font-size: 1.6rem;
  color: var(--deeper-mid-tone);
}

.subsectionContent {
  padding-left: 24px;
  font-size: 1.6rem;
  > div {
    padding: 4px 0px;
  }
}
