.container {
  height: 100vh;
}

.footerButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  :global(.isRtl) & {
    justify-content: flex-start;
  }
}

.btn {
  height: 50px;
  width: 220px;
  max-height: 50px;
  margin-bottom: 0;
}

.codeInput {
  width: 100%;
  max-width: 295px;
  display: flex !important;
  justify-content: space-between;
  margin: 5rem 0;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    width: 7rem;
    font-size: 5rem;
    height: 7rem;
    text-align: center;
    background-color: var(--semi-mid-tone);
  }
}

.resendLink {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
