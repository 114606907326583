.sectionDescription {
  margin-bottom: 15px;
  textarea {
    width: 100%;
  }
}

.sectionInformation {
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--primary);

  :global(.isRtl) & {
    direction: rtl;
  }
}

.sectionHeading {
  margin-bottom: 17px;
}
