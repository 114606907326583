.editQuestionControls {
  display: flex;
  position: relative;
}

.deleteButton {
  position: absolute;
  right: -80px;
  top: -32px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.09;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  width: 33px;

  :global(.isRtl) & {
    right: initial;
    left: -80px;
  }
}

.tooltip {
  position: absolute !important;
  right: -40px;
  top: -50px;
}
