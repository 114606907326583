@import 'scss/variables.scss';

.container {
  display: flex;
  position: absolute;
  width: calc(100% + 2 * #{$header-padding});
  height: 100%;
  margin-left: -$header-padding;
  margin-right: -$header-padding;
  overflow-y: auto;
}
