@import '../../../../scss/variables.scss';

.row {
  height: 100%;
  padding: 0;
  max-width: $desktop-width;
  width: 100%;
  display: flex;
  flex-direction: row;

  :global(.isRtl) & {
    flex-direction: row-reverse;
  }
}
