@import 'variables';

@font-face {
  font-family: 'Neo Sans Arabic';
  src: url('#{$font-path}/NeoSansArabic-Light.eot');
  src: url('#{$font-path}/NeoSansArabic-Light.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/NeoSansArabic-Light.woff2') format('woff2'),
    url('#{$font-path}/NeoSansArabic-Light.woff') format('woff'),
    url('#{$font-path}/NeoSansArabic-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neo Sans Arabic';
  src: url('#{$font-path}/NeoSansArabic.eot');
  src: url('#{$font-path}/NeoSansArabic.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/NeoSansArabic.woff2') format('woff2'),
    url('#{$font-path}/NeoSansArabic.woff') format('woff'),
    url('#{$font-path}/NeoSansArabic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neo Sans Arabic';
  src: url('#{$font-path}/NeoSansArabic-Medium.eot');
  src: url('#{$font-path}/NeoSansArabic-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/NeoSansArabic-Medium.woff2') format('woff2'),
    url('#{$font-path}/NeoSansArabic-Medium.woff') format('woff'),
    url('#{$font-path}/NeoSansArabic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neo Sans Arabic';
  src: url('#{$font-path}/NeoSansArabic-Bold.eot');
  src: url('#{$font-path}/NeoSansArabic-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/NeoSansArabic-Bold.woff2') format('woff2'),
    url('#{$font-path}/NeoSansArabic-Bold.woff') format('woff'),
    url('#{$font-path}/NeoSansArabic-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Neo Sans Arabic';
  src: url('#{$font-path}/NeoSansArabic-Black.eot');
  src: url('#{$font-path}/NeoSansArabic-Black.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/NeoSansArabic-Black.woff2') format('woff2'),
    url('#{$font-path}/NeoSansArabic-Black.woff') format('woff'),
    url('#{$font-path}/NeoSansArabic-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
    url('../components/common/assets/fonts/Roboto/Roboto-Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'),
    url('../components/common/assets/fonts/Roboto/Roboto-Bold.ttf')
      format('truetype');
}
