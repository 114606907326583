@import '../../../scss/responsive.scss';
@import '../../../scss/variables.scss';

.page {
  padding: 1rem 5% 2rem 5%;
  margin-left: -$header-padding;
  margin-right: -$header-padding;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: var(--background-accent);

  @media #{$mobile} {
    height: unset;
  }
}

.metricContainer {
  display: flex;
  width: 100%;
  overflow-x: auto;
  justify-content: space-between;

  @media #{$mobile} {
    flex-direction: column;
    justify-content: center;
  }
}

.tableContainer {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  flex: 1;

  & > div {
    flex: 1 1 0;
    padding: 3rem;
    margin: 1.5rem;
    background-color: var(--background);

    @media #{$mobile} {
      margin: 1rem 0rem;
    }
  }
  @media #{$mobile} {
    flex-direction: column;
    justify-content: center;
  }
}
