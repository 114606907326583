@import 'scss/responsive.scss';
@import 'scss/variables.scss';

.container {
  overflow: auto;
  margin-left: -$header-padding;
  margin-right: -$header-padding;

  @media #{$mobile} {
    margin-left: -$header-padding-mobile;
    margin-right: -$header-padding-mobile;
  }
}

.splash {
  padding: 6rem 6rem 0;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  h1 {
    color: inherit;
  }

  .splashContents {
    display: flex;

    @media #{$mobile} {
      margin: 0 auto;
      h1 {
        margin-bottom: 0;
      }
    }

    .summary {
      border-right: 1px solid #fff;
      text-align: right;
      padding-right: 8.5rem;
      padding-left: 5%;
      @media #{$desktop-sm} {
        padding-left: 3%;
      }
      @media #{$mobile} {
        border-right: none;
        padding-right: 4rem;
        min-width: 23rem;
        padding-right: 0 !important;
        padding-bottom: 2rem;
      }
      h1 {
        line-height: 7rem;
        font-weight: 500;
        @media #{$mobile} {
          line-height: 5rem;
          margin-bottom: 0;
          font-size: 5.6rem;
        }
      }
      padding-bottom: 6rem;
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      :global(.isRtl) & {
        border-right: unset;
        border-left: 1px solid #fff;
        padding-right: unset;
        padding-left: 8.5rem;
      }

      h1 {
        margin-bottom: 7rem;
        @media #{$mobile} {
          margin-bottom: 3rem;
        }
      }

      .deadline {
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 5rem;

        .deadlineIcon {
          margin-right: 1rem;
          :global(.isRtl) & {
            margin-right: unset;
            margin-left: 1rem;
          }
        }
      }

      .technologies {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0.4rem;
      }

      .oneliner {
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 5rem;
        max-width: 45rem;
      }

      .buttons {
        display: flex;
        align-items: center;
        @media #{$mobile} {
          flex-direction: column;
          align-items: flex-end;
          width: 100%;
        }

        .applyButton {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          border: 1px solid #fff;
          transition: border 0.3s;
          border-radius: 0;
          color: #fff;
          font: inherit;
          font-size: 1.2rem !important;
          font-weight: bold;
          padding: 1.3rem 2.9rem;
          text-transform: uppercase;
          &:hover {
            border: 1px solid transparent;
          }
          @media #{$mobile} {
            width: 100%;
            line-height: 1rem;
            height: 5rem;
          }
        }
      }
    }
  }

  @media #{$mobile} {
    padding-top: 1rem;
  }
}

.belowFold {
  display: flex;
  margin: 11rem 17rem 0.1rem;
  position: relative;
  min-height: 35rem;
  background: var(--background);

  @media #{$medium} {
    margin: 9rem 10rem 0;
  }

  @media #{$mobile} {
    margin: 7rem 6rem;
    flex-direction: column;
  }

  .details {
    flex: 3;
    border-right: 1px solid var(--border);
    margin-right: 48.5rem;

    :global(.isRtl) & {
      border-right: unset;
      border-left: 1px solid var(--border);
      margin-right: unset;
      margin-left: 48.5rem;
    }

    @media #{$medium} {
      margin-right: 42.5rem;
      :global(.isRtl) & {
        margin-right: unset;
        margin-left: 42.5rem;
      }
    }

    @media #{$mobile} {
      margin-left: unset;
      margin-right: unset;
      border-right: unset;
      border-left: unset;
    }
  }
  .description {
    border-bottom: 1px solid var(--border);
    margin-right: 13rem;
    padding-bottom: 10rem;

    :global(.isRtl) & {
      margin-right: unset;
      margin-left: 13rem;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
    li {
      font-size: 16px;
      line-height: 24px;
      list-style: inside;
    }

    @media #{$medium} {
      margin-right: 6rem;

      :global(.isRtl) & {
        margin-right: unset;
        margin-left: 6rem;
      }
    }

    @media #{$mobile} {
      margin-left: unset;
      margin-right: unset;
      padding-bottom: 7rem;
    }
  }

  .facilitator {
    margin-top: 10rem;

    & h2 {
      font-weight: 500;
      font-size: 3.1rem;
    }

    @media #{$medium} {
      margin-top: 7rem;
    }

    @media #{$mobile} {
      margin-top: 4rem;
    }

    .logoRow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 3rem;
      padding-bottom: 100px;

      > a {
        flex: 1;
      }

      @media #{$mobile} {
        padding-bottom: 8rem;
      }

      .facilitatorLogo {
        background-color: white;
        padding: 1rem;
        max-height: 14rem;
        max-width: 40rem;
        object-fit: contain;
        margin-right: 6rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        :global(.isRtl) & {
          margin-right: unset;
          margin-left: 6rem;
        }
      }
    }
  }
}

.otherChallenges {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 12rem;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  overflow-y: hidden;
  width: 48.5rem;

  :global(.isRtl) & {
    padding-left: unset;
    padding-right: 12rem;
    left: 0;
    right: unset;
  }

  @media #{$medium} {
    padding-left: 6rem;
    width: 40.5rem;
    :global(.isRtl) & {
      padding-left: unset;
      padding-right: 6rem;
    }
  }

  @media #{$mobile} {
    position: static;
    width: unset;
    padding-left: unset;
    padding-right: unset;
  }

  h3 {
    margin-bottom: 5rem;
    font-size: 2.2rem;
    font-weight: bold;
    @media #{$mobile} {
      margin-bottom: 1rem;
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
