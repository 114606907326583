.tab {
  font-size: 2rem;
  @media (max-width: 430px) {
    font-size: 1.6rem;
  }
  padding: 1rem 3rem;
  border: 3px solid var(--semi-mid-tone);
  box-sizing: border-box;
  cursor: pointer;
  &:not(:last-child) {
    border-right: none;
  }
}

.active {
  border-bottom: 3px solid var(--background-accent);
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 5px;
    background: var(--background);
  }
}
