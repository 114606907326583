.tabs {
  display: flex;
}

.tabPanel {
  border: 3px solid var(--semi-mid-tone);
  border-top: none;
  padding-bottom: 2rem;
  height: 100%;
  overflow-y: auto;
}

.spaceFiller {
  flex: 1;
  border-bottom: 3px solid var(--semi-mid-tone);

  &.leftBorder {
    border-left: 3px solid var(--semi-mid-tone);
  }
}
