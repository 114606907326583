.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0;
  font-size: 1.5rem;
  overflow-y: auto;

  .applicationsLink {
    border-right-color: transparent;
    border-top-color: transparent;
    padding: 0;
    display: flex;

    a {
      padding: 1rem 3rem;
    }
  }
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1rem 0 10rem;
}

.backButton {
  color: var(--deeper-mid-tone);
  font-size: 1.6rem;
  width: auto;
  display: flex;
  border: 0;
  text-decoration: underline;
  &:focus {
    box-shadow: none;
    border-color: transparent;
  }
}
