@import 'scss/responsive.scss';

.navColumn {
  height: calc(100%);
  padding-top: 15px;
  padding-left: 5.5rem;
  :global(.isRtl) & {
    padding-right: 5.5rem;
    padding-left: initial;
  }
  width: 28rem;
  min-width: 24.5rem;
  z-index: 100;
  @media #{$phone} {
    padding-left: 4rem;
    :global(.isRtl) & {
      padding-right: 4rem;
      padding-left: initial;
    }
  }
}

.innerNavContainer {
  height: 100%;
}

.mainColumn {
  position: relative;
  padding-top: 15px;
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1;
}

@media #{$phone} {
  .navColumn {
    padding-left: 4px;
    width: 16rem;
    min-width: 16rem;
  }
}
