@import '../../../scss/responsive.scss';

.rootContainer {
  height: calc(100% - 9rem);
}

.appHeaderContainer {
  display: flex;
  flex-direction: row;
}

.appDataButton {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  margin: 0;
  margin-left: 2rem;
  padding: 0;
  border: none;
}

.contentContainer {
  height: 100%;
}

.innerContentContainer {
  padding: 2rem 2rem 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media #{$medium} {
    padding: 2rem 1rem 0;
  }
}

.h2 {
  text-align: left;
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 3.2rem;
  font-weight: 500;
}

.gateTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3rem;
  margin-top: 16px;
  margin-bottom: 32px;
}
.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: calc(100% - 4rem);
  padding: 4px;
  padding-left: 0px;
}

.column {
  width: 251px;
  height: 100%;
  z-index: 100;

  @media #{$medium} {
    width: 180px;
  }

  @media #{$mobile} {
    width: 90px;
  }
}

.formPanel {
  margin: auto;
  width: 70%;
  padding-top: 53px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.columnR {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  padding: 0;
  align-content: center;
  padding-left: 0;
}

.btnContainer {
  :first-child {
    margin-right: 1rem;
  }
  :last-child {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actionBtn {
  height: 4.5rem;
  font-size: 1.6rem;
  border: 2px solid var(--mid-tone);
  border-radius: 4px;
  line-height: 0.5rem !important;
  white-space: nowrap;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
}

.actionBtnSave {
  color: var(--brand);
  border: 2px solid var(--brand);
  &:hover {
    background: var(--brand);
    color: var(--brand-contrast);
  }
}

.field {
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.warning {
  color: var(--error);
}

.checkbox {
  margin: auto;
}

.backButton {
  color: var(--deeper-mid-tone);
  font-size: 1.6rem;
  width: auto;
  display: inline;
  min-width: auto;
  border: none;
  text-align: left;
  font-weight: normal;
}
