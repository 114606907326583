@import '../../../../../scss/variables.scss';
@import '../../../../../scss/responsive.scss';

.questionLine {
  margin-bottom: 40px;
  width: 100%;
}

%sectionLogo {
  $size: 3vw;
  width: $size;
  height: $size;

  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);

  :global(.isRtl) & {
    left: -45px;
  }
  padding: 0;
  border: none;
}

.sectionIconInner {
  width: 100%;
  height: 100%;
}

.sectionLogoComplete {
  @extend %sectionLogo;
  transform: none;
  padding: 0;
}
.sectionLogoPending {
  @extend %sectionLogo;
  display: none;
}

/* RESPONSIVE */

@media #{$medium} {
  .sectionLogoComplete,
  .sectionLogoPending {
    transform: translateY(50%);
  }
  .questionList {
    padding: 20px;
  }
}

@media #{$phone} {
  .questionLine {
    margin-bottom: 20px;
  }
}
