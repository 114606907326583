@import 'scss/variables';
@import 'scss/responsive';

.container {
  max-height: calc(100vh - #{$header-height});
  height: calc(100vh - #{$header-height});
  position: relative;

  @media #{$mobile} {
    height: calc(100vh - #{$mobile-header-height});
    max-height: calc(100vh - #{$mobile-header-height});
  }
  
  &.collapse {
    max-height: calc(100vh - #{$header-height-collapsed});
    height: calc(100vh - #{$header-height-collapsed});

    @media #{$mobile} {
      height: calc(100vh - #{$mobile-header-height-collapsed});
      max-height: calc(100vh - #{$mobile-header-height-collapsed});
    }
  }
}
