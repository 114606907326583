@import './ViewChallenge.module.scss';
.otherChallengesTitle {
  position: sticky;
}

.otherChallengesList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;

  .scrollIndicator {
    position: absolute;
    width: 34.5rem;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    &.top {
      top: 10.6rem;
      padding-bottom: 16px;
      background: linear-gradient(var(--background) 0%, var(--background-mid-transparent) 50%, transparent 100%);
      > .showMoreCircle {
        transform: rotate(180deg);
      }
    }
    &.bottom {
      bottom: 0;
      padding-bottom: 16px;
      padding-top: 16px;
      background: linear-gradient(transparent 0%, var(--background-mid-transparent) 50%, var(--background) 100%);
    }
    > .showMoreCircle {
      margin: 0;
      padding: 0;
      color: var(--primary);
      border-color: var(--primary);
    }
  }
}
.otherChallenge {
  margin-bottom: 6rem;
  border: none;
  padding: 0;
  text-decoration: none;

  .technologies {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .imageContainer {
    &.topMargin {
      margin-top: 2.3rem;
    }
    height: 19rem;
    width: 34.5rem;
    overflow: hidden;
    background-color: var(--brand);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 2.3rem;
  }

  .heading {
    font-size: 1.8rem;
    font-weight: bold;
  }
}
