@mixin reset-list-style {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@mixin invert {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

@mixin hide-scrollbar {
  $scrollbar-width: 1.3rem;

  overflow: auto;

  @-moz-document url-prefix() {
    & {
      margin-right: -$scrollbar-width;
      overflow-y: scroll;

      [dir="rtl"] & {
        margin-right: initial;
        margin-left: -$scrollbar-width;
      }
    }
  }
}
