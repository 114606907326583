.standardContainer {
  position: absolute;
  bottom: 0;
  height: 50px;
  right: 227px;

  @media only screen and (max-width: 960px) {
    display: none;
  }

  .primaryNav {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    height: 50px;
  }
}

.drawerContainer {
  .primaryNav {
    display: flex;
    flex-direction: column;
    list-style: none;

    .linkContainer {
      display: flex;
      &:not(:last-child) {
        border-bottom: 1px solid var(--border);
      }
    }
  }
}
