.actionButton {
  margin-left: 1rem;

  :global(.isRtl) & {
    margin-left: 0;
    margin-right: 1rem;
  }
}

.updateButton {
  background: var(--primary) !important;
  color: var(--background) !important;
}
