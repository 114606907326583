.loaderContainer {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.lineContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 8rem;
  margin: 2rem;
  filter: invert(100);
  :global(.isRtl) & {
    margin: 0 3rem 0 0;
  }
}

.hubLogo {
  height: 4rem;
  margin: 2rem;
  filter: invert(100);
  :global(.isRtl) & {
    margin: 0 3rem 0 0;
  }
}

.fedLogo {
  height: 7rem;
  margin: 2rem;
  :global(.isRtl) & {
    margin: 0 3rem 0 0;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

.loaderLabel {
  animation: pulse 2s ease 0s infinite;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
}

.loader {
  height: 4px;
  width: 100%;
  overflow: hidden;
  position: relative;
  overflow: hidden;
}

.loader:before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: var(--primary);
  animation: loading 2s linear infinite;
  :global(.isRtl) & {
    animation: loadingRTL 2s linear infinite;
    left: unset;
    right: -200px;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

@keyframes loadingRTL {
  from {
    right: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    right: 50%;
  }
  95% {
    right: 120%;
  }
  to {
    right: 100%;
  }
}
