.container {
  padding: 6rem;
  min-width: 54rem;
}

.title {
  font-size: 2.8rem;
  margin-bottom: 2rem;
  text-align: center;
}

.button {
  margin-top: 3.2rem;
  width: 100%;
}

.checkbox {
  margin-top: 1.5rem;
}

.tooltip {
  z-index: 3000 !important;
  width: 300px;
}

.fieldWithTooltip {
  position: relative;
}

.tooltipIcon {
  position: absolute;
  right: 0;
  top: 8px;
}

.input {
  border-radius: 4px;
  background-color: var(--color-background);
}

.nameInput {
  border: 1px solid var(--primary);
}
