@import '../../../scss/responsive.scss';

.container {
  padding-top: 24px;
  height: calc(100% - 28px);
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 3rem;
}

.tableContainer {
  flex: 1 1 0;
  padding: 3rem;
  max-height: 100%;
}

.clickableIcon {
  cursor: pointer;
}
