@import '../../../scss/responsive.scss';

.icon {
  [data-theme='dark'] & {
    filter: brightness(0);
  }
}

.Toast {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6.5rem;

  background: var(--primary);
  color: var(--background);
  font-size: 1.8rem;
  text-align: center;
  padding: 1.7rem 1.4rem 1.4rem 1.4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: background-color 0.1s ease;

  button {
    border-color: var(--background);
    color: var(--background);
    font-size: 1.4rem;
    padding: 0.3rem 1.8rem;
    margin: 0 0 0 5rem;
  }

  img {
    position: relative;
    top: -2px;
    margin: 0 2rem 0 0;
    height: 2.3rem;
    filter: brightness(0) invert(1); // ToDo get white svg image

    :global(.isRtl) & {
      margin: 0 0 0 2rem;
    }
  }

  @media #{$mobile} {
    font-size: 2.4rem;
  }

  :global(.isRtl) & {
    direction: rtl;
  }
}
