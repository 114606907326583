:root {
  --primary: #333;
  --primary-transparent: rgba(51, 51, 51, 0.1);
  --primary-transparent-tint: rgba(10, 10, 10, 0.45);
  --primary-tinted: #0e0e0e;
  --background: #ffffff;
  --background-accent: #f5f7fa;
  --background-mid-tone: #ffffff;
  --background-mid-transparent: rgba(255, 255, 255, 0.6);
  --brand: #0065b0;
  --brand-contrast: white;
  --brand-accent: #0064a0;
  --mid-tone: #ebebeb;
  --semi-mid-tone: #f2f2f2;
  --deeper-mid-tone: #63666a;
  --contrast-mid-tone: #f2f2f2;
  --border: #cccccc;
  --error: #d4001b;
  --valid: #67c23a;

  --brand-transparent: rgba(0, 101, 176, 0.83);
  --brand-transparent-tint: rgba(0, 101, 176, 0.45);
  --brand-transparent-tint-subtle: rgba(0, 101, 176, 0.1);

  --error-transparent: rgba(212, 0, 27, 0.83);
  --valid-transparent: rgba(103, 194, 58, 0.83);

  --application-selected-color: #2db771;
  --application-rejected-color: #c20131;
  --application-pending-color: #cccccc;

  --checkmark-icon: url('assets/icons/checkmark.svg');

  --footer-background: #000;
  --footer-text: #aaaaaa;
  --footer-title: #fff;

  --forced-text-contrast-bg: rgba(0, 0, 0, 0.83);
  --forced-text-contrast-bg-mid: rgba(0, 0, 0, 0.45);
  --forced-text-contrast-text: #fff;

  --dark-color-secondary: #0a0a0a;
  --dark-grey-primary: #333333;
  --light-color-primary: #1477b1;
  --light-color-grey: #f7f7f7;
  --light-color-greyed: #f4f4f4;
  --light-color-blue: #339ec9;
  --light-color-background: #f0f0f0;
  --red-button-color: #be0000;
  --font-color-grey: #555;
  --font-color-brown: #bcbcbc;
  --font-color-grey-light: #cccccc;
  --font-color-light-white: #cecece;
  --light-color-white: #e2e2e2;
  --item-background: #f9f9f9;
  --checkbox-background: #d5d5d5;
  --button-disabled-background: #f5f5f5;
  --box-shadow-color: #888888;
  --modal-border-color: #979797;
  --button-gradient-color-1: #4bcabd;
  --button-gradient-color-2: #2e9ab2;
  --dot-color: #c5c5c5;
  --no-data-color: #707070;
  --progress-background: #ebebeb;
  --highlight-color: #e00;
  --disabled-text-color: #808080;
  --dark-background-tab: #262626;
  --sugestion-text-color: #120000;
  --input-suggestion-text: #042328;
}

[data-theme="dark"] {
  --primary: #fff;
  --primary-transparent: rgba(255, 255, 255, 0.3);
  --primary-tinted: #f0f2f5;
  --background: #0a0a0a;
  --background-accent: #1e1e1e;
  --background-mid-tone: #111111;
  --background-mid-transparent: rgba(10, 10, 10, 0.7);
  --brand: #0065b0;
  --brand-contrast: white;
  --brand-accent: #0064a0;
  --mid-tone: #ebebeb;
  --semi-mid-tone: #63666a;
  --deeper-mid-tone: #f2f2f2;
  --contrast-mid-tone: #262626;
  --border: #666666;
  --error: #d4001b;
  --valid: #67c23a;

  --brand-transparent: rgba(0, 101, 176, 0.83);
  --brand-transparent-tint: rgba(0, 101, 176, 0.45);
  --brand-transparent-tint-subtle: rgba(0, 101, 176, 0.1);

  --primary-transparent-tint: rgba(10, 10, 10, 0.45);

  --error-transparent: rgba(212, 0, 27, 0.83);
  --valid-transparent: rgba(103, 194, 58, 0.83);

  --application-selected-color: #2db771;
  --application-rejected-color: #c20131;
  --application-pending-color: #cccccc;

  --checkmark-icon: url('assets/icons/checkmark-dark-theme.svg');

  --footer-background: #000;
  --footer-text: #aaaaaa;
  --footer-title: #fff;
}
