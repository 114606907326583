@import 'scss/responsive.scss';
@import 'scss/variables.scss';

.splashFiller {
  flex: 3;
  display: flex;
  align-items: flex-end;
  padding-bottom: 6rem;

  @media #{$mobile} {
    flex: 1 1;
    min-width: 16rem;
  }
}

.showMoreButton {
  display: flex;
  align-items: center;
  margin-left: 7.5rem;

  :global(.isRtl) & {
    margin-left: unset;
    margin-right: 7.5rem;
  }

  @media #{$mobile} {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    margin-left: 0;
    :global(.isRtl) & {
      margin-left: unset;
      margin-right: 3.5rem;
    }
  }
}

.showMoreCircle {
  height: 5rem;
  width: 5rem;
  font-size: 2.5rem;
  border-radius: 2.5rem;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 2rem;
  :global(.isRtl) & {
    margin-right: unset;
    margin-left: 2rem;
  }
  color: white;
}

.showMoreText {
  text-transform: uppercase;
  font-size: 1.6rem;
}
