.link {
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto-Bold, sans-serif;
  text-decoration: none;
  cursor: pointer;
  text-align: left;
  text-transform: uppercase;
  border: none;
  padding: 0;
  line-height: 18px;
}

.linkContainer {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
