.container {
  margin: 2rem;
}

.headerBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.addButton {
  padding: 0 2rem;
}
