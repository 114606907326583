.questionIndex {
  :global(.isRtl) & {
    display: block;
    direction: rtl;
  }
}

.questionTitle {
  width: 100%;
  margin: 15px 0;
}

.questionMainFields {
  display: flex;
  margin-bottom: 15px;

  :global(.isRtl) & {
    flex-direction: row-reverse;
  }
}

.typeSelect {
  margin-right: 14px;

  :global(.isRtl) & {
    margin-right: 0;
    margin-left: 14px;
  }
}

.apiKeySelect {
  margin-right: 14px;

  :global(.isRtl) & {
    margin-right: 0;
    margin-left: 14px;
  }
}

.questionDescription {
  width: 100%;

  textarea {
    width: 100%;
  }
}

.characterLimitations {
  width: 100%;
}

.questionOptions {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid var(--semi-mid-tone);
}

.questionPossibleScore {
  float: right;
}
