@import '../../../scss/variables.scss';

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 3rem;
  background: var(--background);
  margin: auto;

  display: flex;
  justify-content: center;

  -webkit-box-shadow: -1px 5px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 5px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 5px 10px 0px rgba(0, 0, 0, 0.75);
}

.footerButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  :global(.isRtl) & {
    justify-content: flex-start;
  }
}

.footerContainer {
  width: $desktop-width;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  :global(.isRtl) & {
    flex-direction: row-reverse;
  }
}

.btn {
  height: 50px;
  width: 220px;
  max-height: 50px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--background);
  background: var(--primary);
  padding: 0;
}

.addOrgButton {
  margin: 10px 0;
  font-family: NeoSansArabic-Regular !important;
  font-size: 1.6rem;
}

.breadcrumbsContainer {
  margin: 30px 0;
  padding-top: 15px;
  height: 100%;
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
