@import '../../../scss/responsive.scss';

.rootContainer {
  border: none !important;
}

.root {
  background: var(--background);
  color: var(--primary);

  :global(.isRtl) & {
    text-align: right;
  }

  :global(.ql-container) {
    height: 20rem;
  }

  @media #{$medium} {
    padding: 0;
  }

  @media #{$phone} {
    padding: 0;
  }
}

.container {
  display: flex;
  margin: 0 -3rem;

  @media #{$medium} {
    flex-direction: column;
  }

  @media #{$phone} {
    margin: 0;
  }
}

.section {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  padding: 0 3rem;
  margin: 3rem 0;

  &:first-of-type {
    border-right: solid 2px var(--border);
    :global(.isRtl) & {
      border-right: none;
      border-left: solid 2px var(--border);
    }
  }

  @media #{$medium} {
    &:first-of-type {
      border-right: none;
    }
  }
}

.field {
  margin-bottom: 2rem;

  textarea {
    width: 100%;
  }
  input {
    // inputs have an application wide max-width defined in input scss. Overwrite it.
    max-width: unset!important
  }
}

.rejectionLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rejectionModeDisplay {
  width: 50%;
}

.rejectionSendButton {
  height: 50px;
}

.images {
  display: flex;

  @media #{$phone} {
    flex-direction: column;
  }
}

.imageUploader {
  width: calc(100% - 2rem);
  height: 25rem;
  color: var(--sugestion-text-color);
}

.infoLabel {
  margin-top: 1rem;
  display: block;
}

.divider {
  width: 100%;
  border-bottom: solid 2px var(--border);
  margin: 1rem 0 3rem;
}
