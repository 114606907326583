.container {
  border: solid 2px var(--semi-mid-tone);
  padding: 2rem;
  border-radius: 4px;
  height: 100%;
  overflow-y: hidden;
}

.list {
  margin-bottom: 20px;
  max-height: calc(100% - 13rem);
  overflow-y: auto;
}

.listItem {
  width: 100%;
  line-height: 35px;
  position: relative;
}

.innerLi {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.liDivider {
  width: 100%;
  height: 1px;
  background: var(--semi-mid-tone);
  margin: 1rem 0rem;
}

.deleteButton {
  font-size: 1.6rem;
  font-weight: 200;
  border: 2px solid var(--mid-tone);
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  position: absolute;
  padding: 0;
  right: 1rem;
}
