@import '../../../scss/responsive.scss';
@import '../../../scss/typography.scss';
@import '../../../scss/common-styles/inputs.scss';

$icon-path-common: '../assets/icons';
$text-input-height: 45px;

.errorMessage {
  color: var(--error);
}

.errorPlaceholder {
  height: 2.2rem;
}

.readOnly {
  opacity: 0.65;
}

.textArea {
  background: var(--background-accent);
  width: 100%;
  resize: vertical;
  margin: 0;
}

.date,
.time {
  padding: $input-padding;
  background: var(--background-accent);
  color: var(--primary);
  width: 180px;
  font-size: $font-size-input;
  cursor: pointer;

  :global(.isRtl) & {
    flex-direction: row-reverse;
    text-align: right;
    direction: rtl;
  }
}

.date::-webkit-inner-spin-button,
.time::-webkit-inner-spin-button {
  margin-top: 5px;
  -webkit-appearance: none;
  margin: 0;
}

/* disable clear button */
.time[type='time']::-webkit-clear-button {
  -webkit-appearance: none;
  margin: 0;
}

.date {
  width: 100%;
  padding-right: 34px;
}

.dateAdornment {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
}

.text {
  padding: $input-padding;
  border-radius: 1.4px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #042328;
  font-size: 1.56rem;

  :global(.isRtl) & {
    direction: ltr;
    text-align: right;
  }
}

.number {
  padding: $input-padding;
  width: $input-width;
}

.textish {
  margin: $input-margin;
  background: var(--semi-mid-tone);
  border: 2px solid var(--semi-mid-tone);
  border-radius: $input-border-radius;
  color: var( --dark-color-secondary);
  font-size: $input-font-size;
  background-color: var(--brand-contrast);
  height: $text-input-height;

  &.invalid {
    border: 2px solid var(--error);
  }
}

.lengthNote {
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: 5px;
}

.datePicker {
  font-size: 1.2rem !important;
}

@media #{$phone} {
  .date,
  .time {
    width: 160px;
  }
}
