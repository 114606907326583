@charset "UTF-8";
@import "~scss/mixins";
@import "~scss/variables";
@import "~scss/responsive.scss";

$height: 100px;
$width: 6px;

.vertical-dots {
  right: 20px;
  list-style: none;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -10px;
  text-align: right;
  width: initial;
  height: auto;
  body[dir="rtl"] & {
    right: initial;
    left: 20px;
  }

  @media #{$mobile} {
    display: none !important;
  }
}
.vertical-dots li {
  position: relative;
  width: $width;
  height: $height;
  cursor: pointer;
  display: block;
  margin: 15px 0;
}
.vertical-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: $width;
  height: $height;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  background: transparent;
  width: $width;
}

.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before {
  opacity: 1;
}
.vertical-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: $width;
  height: $height;

  content: " ";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // border-radius: 100%;
  background: black;
}

.vertical-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
