.container {
  word-break: break-word;
  .clickableIcon {
    margin: 0px 8px;
    cursor: pointer;
    opacity: 0;
  }

  &:hover {
    .clickableIcon {
      opacity: 1;
    }
  }
}

.reverse {
  display: flex;
  flex-direction: row-reverse;
}
