.datePickerWrapper {
  width: 100%;
  padding-right: 1rem;

  & > div {
    width: 100%;
  }
}

.zonePicker {
  padding-left: 1rem;
}

.horizontalFields {
  display: flex;
}
