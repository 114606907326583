@import '../../scss/responsive';

.container {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2000;
  
  position: fixed;

  @media #{$mobile} {
    height: 100vh;
    width: 100vw;
  }
}

.modal {
  background: var(--background);
  border-radius: 8px;
  border: 1px solid var(--modal-border-color);
  position: relative;
}

.content {
  overflow: auto;
  max-height: 80vh;
  height: 100%;
  padding: 30px;

  @media #{$mobile} {
    height: 100%;
    width: 100vw;
    max-height: unset;
    overflow-x: hidden;
  }
}

.close {
  cursor: pointer;
  display: block;
  margin-left: auto;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 999;
  [data-theme='dark'] & {
    filter: invert(1);
  }
}
