@import '../../../scss/variables.scss';
@import '../../../scss/common-styles/inputs.scss';
@import '../../../scss/responsive.scss';

.errorMessage {
  color: var(--error);
}

.errorPlaceholder {
  height: 2.2rem;
}

:export {
  primary: var(--brand);
  primary25: var(--brand-transparent-tint-subtle);
  neutral: var(--semi-mid-tone);
  selectBorder: $select-border;
  selectBorderRadius: $select-border-radius;
  selectFontSize: $select-font-size;
  selectFontWeight: $select-font-weight;
  background: var(--background-accent);
  contrast: var(--brand-contrast);
  text: #0a0a0a;
  disabledText: #808080;
  white: var(--brand-contrast);
  grey: var(--semi-mid-tone);
}
