.valueBox {
  padding: 1rem 0;
}

.rtl {
  text-align: right;
}

.value {
  font-weight: normal;
  color: var(--deeper-mid-tone);
}
