.optionRow {
  display: flex;
  position: relative;
  margin-bottom: 14px;

  :global(.isRtl) & {
    flex-direction: row-reverse;
  }
}

.answerText {
  margin-right: 14px;

  :global(.isRtl) & {
    margin-right: 0;
    margin-left: 14px;
  }
}

.jumpSelect {
  margin-right: 14px;
  margin-top: 0;

  :global(.isRtl) & {
    margin-right: 0;
    margin-left: 14px;
  }
}

.scoreInput {
  margin-top: 0;
  width: 35%;
}

.deleteButton {
  position: absolute;
  right: -80px;
  top: 10px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.09;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  width: 33px;

  :global(.isRtl) & {
    right: initial;
    left: -80px;
  }
}

.addButton {
  margin-top: 10px;
}
