@import '../../../scss/responsive.scss';

.formOuterContainer {
  padding: 0px 32px;
  height: 100%;
  position: relative;
}

.border {
  border: 2px solid var(--contrast-mid-tone);
}

.form {
  background: var(--background);
  align-content: center;
  min-height: 100%;
  position: relative;
  margin-bottom: 2rem;

  :global(.isRtl) & {
    text-align: right;
  }
}

.formSection {
  background-color: var(--background);
  color: var(--primary);
  margin: auto;
  margin-left: 0px;
  width: 100%;
  padding: 50px;
  font-size: 1.6rem;
  margin-bottom: 4px;
  position: relative;
}

@media #{$medium} {
  .form {
    padding: 40px 60px;
  }

  .formSection {
    padding: 20px;
    min-width: 30vw;
  }
}

@media #{$phone} {
  .formOuterContainer {
    padding: 0;
  }
  .form {
    padding: 0;
  }

  .formSection {
    padding: 20px;
    min-width: 30vw;
  }
}

:export {
  backgroundColor: var(--background);
}
