@import '../../../scss/variables.scss';

.totalScoreContainer {
  display: flex;
  background-color: var(--background-accent);

  :global(.isRtl) & {
    direction: rtl;
  }
}

.totalScoreBox {
  width: 16rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
  border: 3px solid var(--background-accent);
  border-radius: 4px;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.list {
  background-color: var(--background);
  :nth-child(1) {
    margin-top: 0px;
  }
  .sectionTabWrapper > .sectionTitleTabActive :nth-last-child(1) {
    margin-bottom: 4px;
  }
}
