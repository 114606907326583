@import "../scss/responsive.scss";
*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  position: relative;
  

  //this defines what 1 rem is
  /* The default is 16px so 62.5% is 10pixels. If we put 10pixels we override users settings*/
  // font-size: calc(100vw/180);
  font-size: 48%; 
  // font-size: 40%; 

  @media #{$mobile} {
    font-size: 35%;
  }

  @media #{$desktop-xxl} {
    font-size: 62.5%;
  }
  
  overscroll-behavior: none;
}

body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  display: none;
}

.ps__rail-y {
  z-index: 1;
}

.ps__rail-x {
  // z-index: 1;
  top: 0;
  bottom: initial;
}

.ps__thumb-y {
  background: var(--brand);
}
.ps__thumb-x {
  background: var(--brand);
}

.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
  background: var(--brand);
  height: 6px;
}
.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  background: transparent;
  opacity: 0.6;
}

[class*='Editable_element'] {
  ul, ol {
    padding-left: 17px;
  }
}
