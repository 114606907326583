@mixin mock-line {
  display: block;
  height: 12px;
  margin-bottom: 10px;
}

.mock-line-dark-gray {
  @include mock-line;
  background: var(--font-color-brown);
}

.mock-line-light-gray {
  @include mock-line;
  background: var(--font-color-light-white);
}
