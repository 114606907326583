@import "responsive";

$font-family-base: 'Neo Sans Arabic', sans-serif;

$font-color-base: var(--primary);
$font-color-heading: $font-color-base;
$font-color-inverse: var(--primary-tinted);
$font-color-label: $font-color-inverse;
$font-color-input: $font-color-inverse;
$font-color-button: $font-color-inverse;
$font-color-gray: var(--font-color-grey-light);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

$font-size-h1: 5.6rem;
$font-size-h2: 3.6rem;
$font-size-h3: 2.8rem;
$font-size-h4: 1.8rem;
// $font-size-label: 2.32rem;
// Changing it as per Zeplin design, if it conflicts, let me know.
$font-size-label: 1.7rem;
$font-size-input: 2rem;
$font-size-button: 2.08rem;

$line-height-base: 2.2rem;
$line-height-h1: 9.072rem;
$line-height-h2: 7.2rem;
$line-height-h3: 5.6rem;
$line-height-h4: 3.6rem;
$line-height-label: 2.9rem;
$line-height-input: 4.455rem;
$line-height-button: 2.496rem;

html,
body {
  color: $font-color-base;
  font-family: $font-family-base;
  line-height: $line-height-base;
}

@mixin heading {
  color: $font-color-heading;
  font-weight: $font-weight-normal;
}

h1,
.h1 {
  @include heading;
  font-size: $font-size-h1;
  line-height: $line-height-h1;
}

h2,
.h2 {
  @include heading;
  font-size: $font-size-h2;
  line-height: $line-height-h2;
}

h3,
.h3 {
  @include heading;
  font-size: $font-size-h3;
  line-height: $line-height-h3;
}

h4,
.h4 {
  @include heading;
  font-size: $font-size-h4;
  line-height: $line-height-h4;
}

b,
strong {
  font-weight: $font-weight-medium;

  .ql-editor & {
    font-weight: bold;
  }
}

label {
  color: $font-color-label;
  font-size: $font-size-label;
  line-height: $line-height-label;
}

.label-note {
  display: inline-block;
  margin-left: 1rem;
  font-size: 1.624rem;
  color: var(--primary-transparent-tint);

  [dir="rtl"] & {
    margin-right: 1rem;
    margin-left: 0;
  }
}

input {
  font-family: $font-family-base;
  color: $font-color-input;
  font-size: $font-size-input;
  line-height: $line-height-input;
}

textarea {
  font-family: $font-family-base;
  color: $font-color-input;
}

button {
  color: $font-color-button;
  font-size: $font-size-button;
  line-height: $line-height-button;
}

a {
  color: $font-color-base;
  text-decoration: underline;
  cursor: pointer;
}

.label-edit {
  color: $font-color-label;
  cursor: pointer;
  font-size: 1.2rem;

  @media #{$mobile} {
    font-size: 1.9rem;
  }
}

$select-font-weight: $font-weight-medium
