.container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tooltip {
  top: 120px;
}

.tab {
  opacity: 0.5;
  height: 9rem;
  width: 350px;
  min-width: 100px;
  display: flex;
  margin: 0 1.2rem;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.tabActive {
  opacity: 1;
}

.tab:first-child {
  margin-left: 0;
  margin-right: 1.2rem;
}

.tab:last-child {
  margin-left: 1.2rem;
  margin-right: 0;
}

.tabLabel {
  font-size: 2.2rem;
  max-width: 80%;
  overflow: hidden;
  line-height: 1.3;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
}

.tabBlock {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.addTab {
  display: flex;
  flex-direction: row;
}
.tabValue {
  font-size: 18px;
  line-height: 1.09;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0.5rem;
}

.tabBar {
  display: flex;
  height: 0.7rem;
  width: 70%;
  overflow: hidden;
  font-size: 0.75rem;
  border-radius: 10px;
}

.tabBarProgress {
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: center;
  color: var(--brand-contrast);
  text-align: center;
  white-space: nowrap;
  background-color: var(--brand);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  border-radius: 10px;
  border: 1px solid var(--brand);
}

.connectionBlock {
  height: 2.4rem;
  width: 100%;
  background: none;
  font-size: 1.6rem;
}
.activeBlock {
  text-align: center;
}

.deleteButton {
  position: absolute;
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  font-size: 20px;
}
