.container {
  color: var(--primary);
  transition: background-color 0.5s ease;
  width: 100%;
  background: var(--background);
  padding: 30px 50px 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 999;

  &.scrolledDown {
    padding-top: 0;
  }

  @media only screen and (max-width: 960px) {
    padding: 0;
  }
}

.innerContainer {
  width: 100%;
  background: var(--background);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
  position: relative;
  height: 81px;
  transition: height 0.5s ease, background-color 0.5s ease;
  box-sizing: border-box;

  &.scrolledDown {
    height: 51px;
  }

  @media only screen and (max-width: 960px) {
    border: none;
    justify-content: space-between;
    box-sizing: border-box;
    height: 70px;
    padding: 18px 20px 18px;

    &.scrolledDown {
      height: 50px;
      padding: 10px 20px 10px;
    }
  }
}
