@import '../../../scss/responsive';

.slider {
  height: 100%;

  @media #{$mobile} {
    height: 100%;
  }

  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;
    }
  }
}

.popped {
  z-index: 1001;
}

.paddedSlider {
  height: 100%;
}
