.container {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  height: 100%;
}

.contentContainer {
  // overflow: hidden;
  height: 100%;
  overflow-y: auto;
  > .historyContainer:not(:last-child) {
    $content-border-distance: 24px;

    border-bottom: 3px solid var(--background-accent);
    padding-bottom: $content-border-distance;
    margin-bottom: $content-border-distance;
  }
}

.historyContainer {
  font-size: 1.6rem;
  white-space: pre-line;
}

.filterContainer {
  padding: 24px 0px;
}

.emptyNotice {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}
