.container {
  margin-bottom: 1rem;
}

.bar {
  height: 8rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: var(--semi-mid-tone);
  overflow-x: hidden;
  cursor: pointer;
  padding: 0 2rem;
  margin-bottom: 1rem;
}

.expandButton {
  border: none;
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  background-color: var(--deeper-mid-tone);
  color: var(--contrast-mid-tone);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform .5s ease-in-out;
}

.collapse {
  transform: rotate(180deg);
}

.content {
  max-height: 0;
  opacity: 0;
  transition: all .5s ease-in-out;
  overflow: hidden;
}

.open {
  max-height: none;
  opacity: 1;
  transition: all .5s ease-in-out;
}
