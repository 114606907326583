@import '../../../scss/responsive.scss';

.root {
  display: flex;
  justify-content: center;
  background: white;
}

.imagesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  & svg {
    width: 5rem!important;
    height: 5rem!important;
  }
}

.scrollable {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  flex-grow: 1;
}

.imageContainer {
  display: flex;
  flex-grow: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  position: relative;
  min-width: 90%;
  min-height: 90%;
  margin: 0.5rem;
}

.deleteButton {
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 30px;
  width: 30px;
  height: 30px;
}

.image {
  min-width: 100%;
  min-height: 100%;
}
