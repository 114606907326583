.container {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 16px;
  z-index: 15;
  padding: 0;
  border: none;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
}

@mixin bar {
  display: block;
  width: 20px;
  height: 2px;
}

@mixin pseudobar {
  @include bar;
  background-color: var(--primary);

  content: '';
  position: absolute;
  transition: background-color 0.2s ease;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.icon {
  position: relative;
  float: right;
  margin: 6px 0;
  transition: background-color 0s ease;
  transition-delay: 0.1s;
  pointer-events: none;
  z-index: 9;
  @include bar;
  background: var(--primary);
  &.open {
    background: transparent;
    &:before {
      animation-name: burgertop;
    }
    &:after {
      animation-name: burgerbottom;
    }
  }

  &:before {
    @include pseudobar;
    top: -6px;
    animation-name: burgertopBack;
  }

  &:after {
    @include pseudobar;
    top: 6px;
    animation-name: burgerbottomBack;
  }
}

@keyframes burgertop {
  0% {
    top: -6px;
  }

  50% {
    top: 0px;
    transform: rotate(0);
  }

  100% {
    top: 0;
    transform: rotate(45deg);
  }
}

@keyframes burgerbottom {
  0% {
    top: 6px;
  }

  50% {
    top: 0px;
    transform: rotate(0);
  }

  100% {
    top: 0;
    transform: rotate(-45deg);
  }
}

@keyframes burgertopBack {
  0% {
    top: 0px;
    transform: rotate(45deg);
  }

  50% {
    top: 0px;
    transform: rotate(0);
  }

  100% {
    top: 6px;
  }
}

@keyframes burgerbottomBack {
  0% {
    top: 0;
    transform: rotate(-45deg);
  }

  50% {
    top: 0px;
    transform: rotate(0);
  }

  100% {
    top: -6px;
  }
}
