.link {
  padding: 17px 20px 16px;
  line-height: 1.16;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Roboto-Bold', sans-serif;
  letter-spacing: 0.5px;
  text-decoration: none;
  position: relative;

  @media only screen and (max-width: 1422px) {
    padding: 17px 10px 16px;
  }

  @media only screen and (max-width: 1313px) {
    padding: 17px 5px 16px;
  }

  @media only screen and (max-width: 960px) {
    font-size: 13px;
  }

  &.inDrawer {
    padding: 17px 0 16px;
  }

  &.active {
    &:before {
      content: '';
      position: absolute;
      display: block;
      height: 5px;
      width: 100%;
      top: 100%;
      left: 0;
      background-color: var(--primary);
      transition: all 0.25s ease-in-out;
    }
  }
}
