@import "../../../scss/variables";
@import "../../../scss/responsive.scss";

.buttonContainer {
  width: 100%;
  max-width: 585px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 4.8rem;

  @media #{$mobile} {
   justify-content: center;
   margin-top: 6rem;
  }

  .button {
    font-size: 1.98rem;

    @media #{$mobile} {
      width: 50%;
      height: 7rem;
      line-height: 7rem;
      font-size: 2.4rem;
      padding: 0 0;
      border-width: 0.2rem;
    }
  }
}
