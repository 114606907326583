.menu {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.linksContainer {
  padding: 0 20px 20px 20px;
  display: none;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: 70px;
  width: 100vw;
  background: var(--background);
  @media only screen and (max-width: 960px) {
    display: block;
  }
  transform: translateX(-100%);
  transition: all 0.25s ease-in-out;
  z-index: 10;
  border-top: 1px solid var(--border);

  &.scrolledDown {
    top: 50px;
  }

  &.open {
    transform: translateX(0);
  }
}

.drawerBackground {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: none;

  &.open {
    @media only screen and (max-width: 960px) {
      display: block;
    }
  }
}

.modeButtons {
  display: none;
  position: relative;
  margin: 0 auto;
  &.open {
    @media only screen and (max-width: 960px) {
      display: flex;
    }
  }
}
