.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 3rem 3rem 0rem;
  font-size: 1.6rem;
  overflow-x: hidden;
}
.reviewOptionGroup {
  position: relative;
}

.buttonContainer {
  width: 100%;
}

.acceptButton {
  display: block;
}

.button {
  width: 100%;
  margin-bottom: 5px;
}

.comment > textarea {
  width: 100%;
  height: 20rem;
}

.tooltip {
  position: absolute;
}

.superReviewButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .superReviewButton {
    width: 30%;
  }
}

.descriptionTooltip {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--brand);
  cursor: pointer;
}

.reassignTarget {
  margin-top: 1.5rem;
}
