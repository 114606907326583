.tableContainer {
  flex: 1 1 0;
  display: flex;
  margin: 3rem;
  max-height: calc(100% - 4rem);
  overflow: hidden;
  box-sizing: border-box;

  > div {
    width: 100%;
  }
}

.tableActionButtonList {
  display: flex;
  justify-content: space-between;
  width: 5rem;
  padding: 0px 0.5rem;
}
