@import '../../../scss/variables.scss';
@import '../../../scss/responsive.scss';

.container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-top: 45px; // becauase 30px from breadcrumb bottom + 15px from padding top of top container
  padding-bottom: 9rem; // fixed footer that has 9rem tall, so add this padding to make sure the contents are not covered by the footer.
}

.breadcrumbsContainer {
  height: 2.5rem; // line height of the breadcrumbs
  margin-bottom: 32px !important;
}

.contentContainer {
  height: unset;
  flex: 1;
  & > div > div {
    min-height: 50vh;
  }
}

.outerFormBox {
  margin-top: 25px;
  word-wrap: break-word;
}

.addSectionButton {
  margin: 40px 40px 40px 0;
}

.getToNextSectionNote {
  font-size: 1.6rem;
  text-align: left;
  padding: 2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--brand);
  background-color: var(--background);

  :global(.isRtl) & {
    text-align: right;
    direction: rtl;
  }
}

.sectionDescription {
  background: white;
  padding: 20px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;

  font-size: 1.6rem;
  text-align: left;
  line-height: 1.5;
}

.footerButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  :global(.isRtl) & {
    justify-content: flex-start;
  }
}

.progressBarCol {
  height: 1.5rem;
  flex-grow: 0;
  max-height: 1.5rem;
  width: 32.2rem;
}

.progressBarAndLabelWrapper {
  display: flex;

  :global(.isRtl) & {
    flex-direction: row-reverse;
  }
}

.progressTextCol {
  flex-grow: 0;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--brand);
  margin-left: 2rem;
  :global(.isRtl) & {
    margin-right: 2rem;
    margin-left: 0;
  }
}

.footerPotentialScoreTotal {
  margin: -15px 30px;
  width: 95px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  border: solid 5px var(--semi-mid-tone);
}

.submitButton {
  height: 5rem;
  min-width: 15rem;
  max-height: 5rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--background);
  background: var(--primary);
  padding: 0 2rem;
  position: relative;
}

ul {
  text-align: left;
  list-style: none;
}

@media #{$medium} {
  .column {
    width: 220px;
    min-width: 160px;
  }
}

@media #{$phone} {
  .contentContainer {
    padding: 0px !important;
  }

  .sectionTabColumn {
    min-width: unset !important;
    width: 25vw !important;
  }

  .column {
    width: 135px;
    min-width: 120px;
    margin-left: -20px;
    :global(.isRtl) & {
      padding-right: 0rem;
    }
  }
  .row {
    margin-bottom: 80px;
  }

  /* footer */
  .footerContainer {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .footer {
    padding: 10px;
  }
  .progressBarAndLabelWrapper {
    flex-direction: column;
    align-items: center;
  }
  .progressBarCol {
    height: 10px;
    max-height: 10px;
    width: 150px;
    margin-bottom: 4px;
  }
  .progressTextCol {
    margin-top: 4px;
    margin-left: 5px;
    width: 100%;
  }
  .progressSubmitBtnActive,
  .progressSubmitBtnDisabled {
    height: 30px;
    width: 100px;
    max-height: 30px;
  }

  .addSectionButton {
    margin: 20px 20px 20px 0;
  }

  .formEditorRow {
    padding: 0 5px !important;
  }
}

.unorderedList {
  padding-bottom: 2rem;
}

:export {
  lineColor: var(--primary);
  lineBackground: var(--contrast-mid-tone);
}
