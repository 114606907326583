.container {
  padding: 6rem;
  min-width: 54rem;
}

.title {
  font-size: 2.8rem;
  margin-bottom: 2rem;
  text-align: center;
}

.button {
  margin-top: 3.2rem;
  padding: 0 2rem !important;
  width: 100%;
}

.input {
  background-color: var(--background-accent);
}
