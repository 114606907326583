@charset "UTF-8";
@import "~scss/mixins";
@import "~scss/variables";
@import "~scss/responsive.scss";
// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-image-path: "#{$icon-path}";
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: white !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 0.5 !default;
$slick-opacity-not-active: 0.09 !default;
$slick-arrow-distance: 0;
$slick-size: 50px;
$slick-padding-inner: 0;
$slick-padding-outer: 4vw;

/* Arrows */

.slick-prev,
.slick-next {
  z-index: 1;
  position: absolute;
  display: block;
  box-sizing: content-box;
  height: $slick-size;
  width: $slick-size;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  top: 46.6%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border: none;
  transition: all 0.2s ease;
  color: $slick-arrow-color;

  svg {
    width: 100% !important;
    height: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('#{$slick-image-path}/arrow.svg');
    background-position: 70% center;
    background-repeat: no-repeat;
    background-size: 25px;
    @include invert;

    @media #{$mobile} {
      background-image: url('#{$slick-image-path}/arrow-blue.svg');
    }
  }

  &:hover, &:focus {
    background: transparent;
    box-shadow: none;

    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled {
    cursor: unset;
    opacity: $slick-opacity-not-active;
    visibility: hidden;
  }
}

.slick-prev {
  left: $slick-arrow-distance;
  padding-left: $slick-padding-outer;
  padding-right: $slick-padding-inner;

  // [dir="rtl"] & {
  //   left: auto;
  //   right: $slick-arrow-distance;
  //   transform: rotate(180deg);
  // }

}

.slick-next {
  right: $slick-arrow-distance;
  padding-left: $slick-padding-inner;
  padding-right: $slick-padding-outer;

  // [dir="rtl"] & {
  //   left: $slick-arrow-distance;
  //   right: auto;
  //   transform: rotate(180deg);
  // }

  &:before {
    transform: scaleX(-1);
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  height: 2rem; /* place it as the same height as the back button when mobile */

  position: absolute;
  bottom: 5rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  @media #{$mobile} {
    bottom: 1rem;
  }

  $height: 6px;
  $width: 120px;
  $max-width: 6vw;

  li {
    position: relative;
    display: inline-block;
    height: $height;
    width: $width;
    max-width: $max-width;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: $height;
      width: $width;
      max-width: $max-width;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;

      &:hover, &:focus {
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: " ";
        height: $height;
        width: $width;
        max-width: $max-width;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: $slick-dot-color;
      }
    }

    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

.slick-slider--minimal {
  $slick-size: 15px;

  .slick-prev,
  .slick-next {
    top: 50%;
    height: $slick-size;
    width: $slick-size;

    &:before {
      filter: unset;
      -webkit-filter: unset;
      background-size: $slick-size;
    }
  }
}
