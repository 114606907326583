.root {
  background: white;
  border: 2px solid var(--semi-mid-tone);
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 0.3rem 1.5rem;
}

.input {
  border: none;
  width: 100%;
  padding: 0;
  background-color: white;
  color: black;
}

.tagList {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.tagItem {
  align-items: center;
  background: var(--semi-mid-tone);
  border-radius: 2px;
  color: var(--primary);
  display: flex;
  list-style: none;
  margin-right: 5px;
  padding: 5px 10px;
}

.tagButton {
  margin-left: 8px;
}

.tagItemInputContainer {
  background: none;
  flex-grow: 1;
  padding: 0;
}
